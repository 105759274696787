import "components/shared/button/CSCategoryButton.scss";

import { Button, ButtonProps } from "react-bootstrap";

import CSIconWithText from "components/shared/icon/CSIconWithText";
import React from "react";
import classNames from "classnames";
import { isNullOrUndefOrEmpty } from "@civicscience/chops";

type CSCategoryButtonProps = Omit<ButtonProps, "children"> & {
  buttonText: string;
  buttonIcon?: string;
  tooltipText?: string;
  bgGradient?: string;
};

/**
 * Renders button with a background gradient.
 * See https://react-bootstrap.github.io/components/buttons/#api for props.
 */
const CSCategoryButton = ({
  className,
  variant = "light",
  buttonText = "",
  buttonIcon = "",
  type = "button",
  tooltipText = "",
  size,
  bgGradient,
  ...buttonProps
}: CSCategoryButtonProps) => {
  if (isNullOrUndefOrEmpty(buttonText)) {
    return null;
  }

  const bgClassName = bgGradient ? `bg-${bgGradient}` : null;
  const srOnlyText = size !== "lg";

  return (
    <Button
      {...buttonProps}
      variant={variant}
      className={classNames("csa-category-btn", className, bgClassName)}
      type={type}
      size={size}
      title={tooltipText ? tooltipText : buttonText}
    >
      <CSIconWithText
        text={buttonText}
        isTextVisible={false}
        textClassName={classNames("csa-category-btn-text", { "visually-hidden": srOnlyText })}
        iconLeftClassName={classNames("csa-category-btn-icon", buttonIcon)}
      />
    </Button>
  );
};

export default CSCategoryButton;
