import { User } from "types";
import { isNullOrUndef } from "@civicscience/chops";

const buildUserDisplayName = (userObject: User): string => {
  const { firstName, lastName, userInfo } = userObject;
  const { email = "" } = userInfo;

  return isNullOrUndef(lastName) ? email.trim() : `${firstName} ${lastName}`.trim();
};

export default buildUserDisplayName;
