import {
  ComponentScoreFull,
  CreateMultiScoreArgs,
  GetMultiScoreResultsArgs,
  MultiScore,
  MultiScoreIdType,
  MultiScoreResults,
  MultiScoreTimeviewResults,
} from "types";

import API from "api/client";
import { envConfig } from "config";

const { multiScoresAPI } = envConfig;

/**
 * Creates a Multi Score with the provided settings and scores
 */
const createMultiScore = (req: CreateMultiScoreArgs): Promise<MultiScore> => {
  return API.post<MultiScore>(`${multiScoresAPI}`, req).then((res) => res.data);
};

/**
 * Gets a Multi Score and its scores
 */
const getMultiScore = (id: MultiScoreIdType): Promise<MultiScore> => {
  return API.get<MultiScore>(`${multiScoresAPI}${id}`).then((res) => res.data);
};

/**
 * Get results for MultiScore
 */
const getMultiScoreTimeviewResults = async (options: GetMultiScoreResultsArgs): Promise<MultiScoreTimeviewResults> => {
  const { multiScoreId, targetId, weightingSchemeName, segmentUUID, network, startDate, endDate } = options;
  const { data } = await API.get<MultiScoreTimeviewResults>(`${multiScoresAPI}${multiScoreId}/results/timeview`, {
    params: {
      targetId,
      wscheme: weightingSchemeName,
      segment: segmentUUID,
      network,
      startDate,
      endDate,
    },
  });
  return data;
};

/**
 * Gets a Multi Score component scores
 */
const getMultiScoreComponentScores = (id: MultiScoreIdType): Promise<ComponentScoreFull[]> => {
  return API.get<ComponentScoreFull[]>(`${multiScoresAPI}${id}/scores`).then((res) => res.data);
};

/**
 * Get results for MultiScore
 */
const getMultiScoreResults = async (options: GetMultiScoreResultsArgs): Promise<MultiScoreResults> => {
  const { multiScoreId, targetId, weightingSchemeName, segmentUUID, network, startDate, endDate } = options;
  const { data } = await API.get<MultiScoreResults>(`${multiScoresAPI}${multiScoreId}/results`, {
    params: {
      targetId,
      wscheme: weightingSchemeName,
      segment: segmentUUID,
      network,
      startDate,
      endDate,
    },
  });
  return data;
};

/**
 * Updates a Multi Score with the provided settings and scores
 */
const updateMultiScore = (id: MultiScoreIdType, req: CreateMultiScoreArgs): Promise<MultiScore> => {
  return API.put<MultiScore>(`${multiScoresAPI}${id}`, req).then((res) => res.data);
};

/**
 * Soft deletes multi score with id
 */
const deleteMultiScore = (id: MultiScoreIdType): Promise<void> => {
  return API.delete<void>(`${multiScoresAPI}${id}`).then((res) => res.data);
};

export default {
  create: createMultiScore,
  get: getMultiScore,
  getTimeview: getMultiScoreTimeviewResults,
  getResults: getMultiScoreResults,
  listComponentScores: getMultiScoreComponentScores,
  update: updateMultiScore,
  delete: deleteMultiScore,
};
