import DOMPurify from "dompurify";
import React from "react";

type SafeHtmlProps<T extends React.ElementType> = {
  as?: T;
  html: string;
  className?: string;
};

const SafeHtml = <T extends React.ElementType>({
  html,
  as,
  className,
  ...restProps
}: SafeHtmlProps<T> & Omit<React.ComponentPropsWithoutRef<T>, keyof SafeHtmlProps<T>>): JSX.Element => {
  const TagType = as || "div";
  return (
    <TagType className={className} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html, {}) }} {...restProps} />
  );
};

export default SafeHtml;
