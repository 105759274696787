/**
 * Splits an array into 2 arrays.
 * The first array contains the elements for which the predicate returned `true`.
 * The second array are the elements for which the predicate returned `false`.
 *
 * Note: This can be moved to chops for the next release.
 * Also, I would like for us to evaluate [remeda](https://remedajs.com/)
 * for these kinds of general utilities.
 */
const partition = <T>(xs: T[], predicate: (x: T) => boolean): [T[], T[]] => {
  const result: [T[], T[]] = [[], []];
  xs.forEach((item) => {
    const matches = predicate(item);
    result[matches ? 0 : 1].push(item);
  });
  return result;
};

export default partition;
