//TODO: Move to Chops?

const isKeyValueInLocalStorage = (storageKey: string, expectedValue: string, catchReturn = false): boolean => {
  try {
    return localStorage.getItem(storageKey) === expectedValue;
  } catch {
    return catchReturn;
  }
};

export default isKeyValueInLocalStorage;
