import { RouteMapItemSimple } from "types";

const getPathNamesFromRoutes = (routePathsArray: RouteMapItemSimple[]) =>
  routePathsArray.map((url) => {
    const urlPath = url.path ?? "";
    const urlPathWithoutParams = urlPath.match(/^[^:]*/);
    return `${urlPathWithoutParams}`;
  });

export default getPathNamesFromRoutes;
