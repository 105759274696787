import React, { createContext, useContext } from "react";

import { MultiTimeview } from "types";
import { isNullOrUndefOrEmpty } from "@civicscience/chops";
import { useMultiTimeview } from "queries";

type MultiTimeviewProviderProps = {
  uuid: string;
  children: React.ReactNode;
};

type MultiTimeviewContextProps = {
  currentMultiTimeviewUUID: string;
  currentMultiTimeview?: MultiTimeview | null;
  multiTimeviewIsFetching?: boolean;
  multiTimeviewIsLoaded?: boolean;
  multiTimeviewError?: unknown;
};

const emptyContext = {
  currentMultiTimeviewUUID: "",
  currentMultiTimeview: null,
  multiTimeviewIsLoaded: false,
  multiTimeviewError: null,
};

export const MultiTimeviewContext = createContext<MultiTimeviewContextProps>(emptyContext);
MultiTimeviewContext.displayName = "MultiTimeviewContext";
/**
 * MultiTimeviewProvider provides the currently selected MultiTimeview
 */
const MultiTimeviewProvider = ({ uuid, children }: MultiTimeviewProviderProps) => {
  const {
    data: currentMultiTimeview,
    isFetching: multiTimeviewIsFetching,
    error: multiTimeviewError,
  } = useMultiTimeview(uuid, {
    enabled: !isNullOrUndefOrEmpty(uuid),
  });

  const multiTimeviewIsLoaded = !!currentMultiTimeview;

  return (
    <MultiTimeviewContext.Provider
      value={{
        currentMultiTimeviewUUID: uuid,
        currentMultiTimeview,
        multiTimeviewIsLoaded,
        multiTimeviewIsFetching,
        multiTimeviewError,
      }}
    >
      {children}
    </MultiTimeviewContext.Provider>
  );
};

/**
 * Functionally provides the currently selected Multi Timeview
 */
export const useMultiTimeviewState = () => {
  const {
    currentMultiTimeviewUUID,
    currentMultiTimeview,
    multiTimeviewIsFetching,
    multiTimeviewIsLoaded,
    multiTimeviewError,
  } = useContext(MultiTimeviewContext);

  return {
    currentMultiTimeviewUUID,
    currentMultiTimeview,
    multiTimeviewIsFetching,
    multiTimeviewIsLoaded,
    multiTimeviewError,
  };
};

export default MultiTimeviewProvider;
