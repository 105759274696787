import { envConfig } from "config";

const { appEnv, nodeEnv } = envConfig;

export const isNodeEnvTest = () => nodeEnv === "test";

export const isNodeEnvDevelopment = () => nodeEnv === "development";

export const isNodeEnvProduction = () => nodeEnv === "production";

export const isEnvLocalOnly = () => isNodeEnvDevelopment() || appEnv === "local";

export const isEnvQA = () => appEnv === "qa";

export const isEnvProd = () => appEnv === "production";

export const isEnvLocalOrQA = () => isEnvLocalOnly() || isEnvQA();
