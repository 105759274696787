import { MultiTimeview, MultiTimeviewForm, MultiTimeviewSimple, MultiTimeviewTimeviewResults } from "types";

import API from "api/client";
import { envConfig } from "config";
import { normalizeEndDate } from "utils";

export type GetMultiTimeviewResultsArgs = {
  uuid: string;
  startDate?: Date | null;
  endDate?: Date | null;
};
const { multiTimeviewsAPI } = envConfig;

const getMultiTimeview = (uuid: string): Promise<MultiTimeview> => {
  return API.get<MultiTimeview>(`${multiTimeviewsAPI}${uuid}`).then((res) => res.data);
};

const getAllMultiTimeviews = (): Promise<MultiTimeviewSimple[]> => {
  return API.get<MultiTimeviewSimple[]>(`${multiTimeviewsAPI}`).then((res) => res.data);
};

const createMultiTimeview = (req: MultiTimeviewForm): Promise<MultiTimeview> => {
  return API.post(`${multiTimeviewsAPI}`, req).then((res) => res.data);
};

const updateMultiTimeview = (uuid: string, req: MultiTimeviewForm): Promise<MultiTimeview> => {
  return API.put(`${multiTimeviewsAPI}${uuid}`, req).then((res) => res.data);
};

const deleteMultiTimeview = (uuid: string): Promise<void> => {
  return API.delete(`${multiTimeviewsAPI}${uuid}`).then((res) => res.data);
};

const getMultiTimeviewTimeviewResults = (
  options: GetMultiTimeviewResultsArgs,
): Promise<MultiTimeviewTimeviewResults> => {
  const { uuid, startDate, endDate } = options;
  return API.get<MultiTimeviewTimeviewResults>(`${multiTimeviewsAPI}${uuid}/results`, {
    params: {
      startDate,
      endDate: normalizeEndDate(startDate, endDate),
    },
  }).then((res) => res.data);
};

export default {
  get: getMultiTimeview,
  getAll: getAllMultiTimeviews,
  create: createMultiTimeview,
  update: updateMultiTimeview,
  delete: deleteMultiTimeview,
  timeviewResults: getMultiTimeviewTimeviewResults,
};
