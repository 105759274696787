import { pushToDataLayer, trackIntercomEvent } from "utils";

const trackEvent = (name: string, metadata = null): void => {
  //Track to Intercom
  trackIntercomEvent(name, metadata);

  // GTM
  pushToDataLayer({
    event: name,
    eventProps: {
      metadata,
      // category: category,
      // action: action,
      // label: label,
      // value: value
    },
  });
};

export default trackEvent;
