import { isNullOrUndefOrEmpty } from "@civicscience/chops";

/**
 * Values that can represent a number.
 *
 * Useful for formatter functions/components that want to accept a broader input.
 */
export type NumberLike = number | string;

/**
 * Display formats for a number.
 */
export type NumberDisplayFormat = "number" | "percent";

/**
 * Options when formatting a number for display.
 */
export type NumberFormatOptions = { formatType: NumberDisplayFormat } & Intl.NumberFormatOptions;

/**
 * Attempts to parse the unknown value to a number.
 * If it cannot be parsed, then the fallback is returned instead.
 */
export const parseNumberOrDefault = <T>(x: unknown, fallback: T): number | T => {
  if (isNullOrUndefOrEmpty(x) || (typeof x !== "string" && typeof x !== "number")) {
    return fallback;
  }

  const parsed = Number(x);
  return isNaN(parsed) ? fallback : parsed;
};

/**
 * Attempts to parse the unknown value to a number.
 * Returns `null` if it cannot be parsed.
 *
 * See `parseNumberOrDefault` if you need to specify a specific fallback value.
 */
export const parseNumberOrNull = (x: unknown): number | null => {
  return parseNumberOrDefault(x, null);
};

/**
 * Internal function to format a number to a string by `NumberDisplayFormat` type.
 */
const formatNumberByDisplayFormat = (
  x: number,
  { formatType = "number", ...formatOptions }: Partial<NumberFormatOptions> = {},
): string => {
  const result = x.toLocaleString(undefined, {
    maximumFractionDigits: 2,
    ...formatOptions,
  });
  switch (formatType) {
    case "number":
      return result;
    case "percent":
      return `${result}%`;
  }
};

/**
 * Formats a `NumberLike` to a string via an `output` function.
 * This allows you to run a custom output only if the number successfully parses.
 * Note that this can also handle percentages via a `options.formatType`
 *
 * The `Output` is generic so that this can be reused for JSX.
 * If you are using this in regular TS code see `formatNumber` below which is specialized to a string.
 */
export const formatNumberOrDefault = <Output>(
  x: NumberLike | null | undefined,
  output: (formatted: string, parsed: number) => Output,
  fallback: Output,
  options: Partial<NumberFormatOptions> = {},
): Output => {
  const parsedNumber = parseNumberOrNull(x);
  if (isNullOrUndefOrEmpty(parsedNumber)) {
    return fallback;
  }

  return output(formatNumberByDisplayFormat(parsedNumber, options), parsedNumber);
};

/**
 * Formats a number to a string based on the provided options.
 *
 * See `formatNumberOrDefault` if you need fine grained control over the output.
 */
export const formatNumber = (x: NumberLike | null | undefined, options: Partial<NumberFormatOptions> = {}) => {
  return formatNumberOrDefault(x, (formatted) => formatted, "", options);
};

/**
 * Formats a number to a percent string based on the provided options.
 *
 * See `formatNumberOrDefault` if you need fine grained control over the output.
 */
export const formatPercent = (x: NumberLike | null | undefined, options: Partial<NumberFormatOptions> = {}) => {
  return formatNumberOrDefault(x, (formatted) => formatted, "", {
    ...options,
    formatType: "percent",
  });
};
