import "components/shared/button/CSPrimaryButton.scss";

import CSIconLink, { CSIconLinkProps } from "components/shared/links/CSIconLink";
import React, { ReactNode } from "react";

import classNames from "classnames";
import { isNullOrUndefOrEmpty } from "@civicscience/chops";

type CSPrimaryButtonLinkProps = Omit<CSIconLinkProps, "iconText"> & {
  buttonText?: ReactNode;
  title?: string;
  to: string;
  iconClassName?: string;
  textClassName?: string;
  ariaLabel?: string;
  isSubmitting?: boolean;
  hideUnderline?: boolean;
  children?: React.ReactNode;
  size?: "sm" | "md" | "lg";
};

/**
 * Renders a link that is styled for a primary button action.
 */
const CSPrimaryButtonLink = ({
  buttonText,
  title,
  ariaLabel = title,
  to,
  className,
  icon,
  iconClassName,
  dataTestId,
  isSubmitting = false,
  isTextVisible = true,
  hideUnderline = true,
  textClassName,
  size = "md",
  children,
  ...linkProps
}: CSPrimaryButtonLinkProps) => {
  if (isNullOrUndefOrEmpty(buttonText)) {
    return null;
  }

  const btnSizeClassName = size === "sm" ? "btn-sm" : size === "lg" ? "btn-lg" : undefined;

  return (
    <CSIconLink
      {...linkProps}
      to={to}
      className={classNames("csa-primary-btn btn btn-primary", btnSizeClassName, className, {
        "text-decoration-none": hideUnderline,
      })}
      title={title}
      aria-label={ariaLabel}
      iconText={buttonText}
      aria-disabled={isSubmitting}
      iconClassName={isSubmitting ? "fas fa-sync fa-spin" : iconClassName}
      icon={icon}
      dataTestId={dataTestId}
      isTextVisible={isTextVisible}
      textClassName={textClassName}
    >
      {children}
    </CSIconLink>
  );
};

export default CSPrimaryButtonLink;
