import { ObjectValues } from "types";

const QuestionSearchTextType = {
  QUESTIONS: "questions",
  ANSWERS: "answers",
  TAGS: "tags",
} as const;

export type QuestionSearchTextTypeTerm = ObjectValues<typeof QuestionSearchTextType>;

export default QuestionSearchTextType;
