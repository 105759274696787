import { Tag, TaxonomyNode, TaxonomyNodeApi } from "types";

import { envConfig } from "config";
import { parseNumberOrNull } from "utils/numbers";

/**
 * Represents the value of a CS Tag in the tree.
 * This uses a combination of the tag's id AND name.
 * We ultimately want to send the name to the API, but names are not unique so we combine it with the ID.
 *
 * example: "tag.54_Brands.57_CPG"
 *
 * TODO: See if the server can de-dupe any tags with the same name.
 * This would allow us to just store the name and know it is unique.
 */
export type CSTagNodeValue = string;

/**
 * The string used to combine each part of the path into a full path for a given tag.
 */
const VALUE_DELIMITER = ".";

/**
 * The string used to combine a tag's id and value into a single unique string value.
 */
const ID_NAME_DELIMITER = "_";

/**
 * The string with which every Tags will start.
 * We need to be able to distinguish Tags from CS Tags from Taxonomy Nodes and this leading marker will allow that.
 */
const TAGS_ROOT_NAME = "tag";

/**
 * The string with which every CS NodeValue will start.
 * We need to be able to distinguish CS Tags from Taxonomy Nodes and this leading marker will allow that.
 */
const CS_TAGS_ROOT_NAME = "cs-tag";

/**
 * Determines if a provided string represents a Tag.
 */
const isTagNodeValue = (x: string): x is CSTagNodeValue =>
  x.startsWith(`${TAGS_ROOT_NAME}${VALUE_DELIMITER}`) || x.startsWith(`${CS_TAGS_ROOT_NAME}${VALUE_DELIMITER}`);

/**
 * Constructs a string value from the provided node and parent.
 *
 * example: "tag.54_Brands.57_CPG"
 */
const encodeCSTagNodeValue = (
  parent: TaxonomyNode | undefined,
  node: TaxonomyNodeApi,
  isCSTag: boolean = false,
): CSTagNodeValue => {
  if (!parent) {
    // give the root node a special value which allows us to know every descendant is a CS Tag
    return isCSTag ? CS_TAGS_ROOT_NAME : TAGS_ROOT_NAME;
  }
  const val = node.taxonomyNodeId ? `${node.taxonomyNodeId}${ID_NAME_DELIMITER}${node.name}` : node.name;
  return `${parent.value}${VALUE_DELIMITER}${val}`;
};

const encodeRootCSTagNodeValue = (node: Tag, accountRefid?: number | null): CSTagNodeValue => {
  const tagRootName = !!accountRefid && accountRefid !== 11 ? TAGS_ROOT_NAME : CS_TAGS_ROOT_NAME;
  return encodeCSTagNodeValue(
    { value: tagRootName, label: tagRootName },
    { taxonomyNodeId: node.id, name: node.label, nodes: [] },
  );
};

/**
 * Encodes the special "trending" tag so that pages from _outside_ of question search
 * can more easily construct a link with this tag pre-selected.
 *
 * Specifically, we show a link on the Homepage for "Trending Questions" which uses
 * this to link into Question Search with the trending tag pre-selected.
 */
const encodeTrendingTagValue = () =>
  encodeCSTagNodeValue(
    { value: TAGS_ROOT_NAME, label: TAGS_ROOT_NAME },
    { taxonomyNodeId: parseNumberOrNull(envConfig.trendingTagId), name: "trending", nodes: [] },
  );

/**
 * Extracts the tag names from a single CSTagNodeValue.
 *
 * example: tag.54_Brands.57_CPG -> ["Brands", "CPG"]
 */
const decodeCSTagNodeValue = (value: CSTagNodeValue): string[] => {
  const idsAndNames = value.split(VALUE_DELIMITER).filter((x) => x !== TAGS_ROOT_NAME && x !== CS_TAGS_ROOT_NAME);
  return idsAndNames.map((x) => x.split(ID_NAME_DELIMITER)[1]);
};

/**
 * Extracts the tag names from a single CSTagNodeValue.
 *
 * example: tag.54_Brands.57_CPG -> ["54", "57"]
 */
const decodeCSTagNodeId = (value: CSTagNodeValue): number[] => {
  const idsAndNames = value.split(VALUE_DELIMITER).filter((x) => x !== TAGS_ROOT_NAME && x !== CS_TAGS_ROOT_NAME);
  return idsAndNames.map((x) => parseInt(x.split(ID_NAME_DELIMITER)[0]));
};

/**
 * Builds a list of distinct tag names from an array of CSTagNodeValue instances.
 *
 * ["tag.54_Brands.57_CPG", "tag.1_Retail"] -> ["Brands", "CPG", "Retail"]
 */
const buildCSTagNodeApiValues = (selections: CSTagNodeValue[]): string[] => {
  const uniqueIds = selections.reduce((result: Set<string>, current: string) => {
    const tagNames = decodeCSTagNodeValue(current);
    tagNames.forEach((name) => result.add(name));
    return result;
  }, new Set<string>());

  return Array.from(uniqueIds);
};

/**
 * Builds a list of distinct tag ids from an array of CSTagNodeValue instances.
 *
 * ["tag.54_Brands.57_CPG.1_Retail", "tag.1_Retail"] -> ["54", "57", "1"]
 */
const buildCSTagNodeDashletApiValues = (selections: CSTagNodeValue[]): number[] => {
  const uniqueIds = selections.reduce((result: Set<number>, current: string) => {
    const tagNames = decodeCSTagNodeId(current);
    tagNames.forEach((id) => result.add(id));
    return result;
  }, new Set<number>());

  return Array.from(uniqueIds);
};

export default {
  isTagNodeValue,
  encodeValue: encodeCSTagNodeValue,
  encodeRootTagValue: encodeRootCSTagNodeValue,
  buildApiValues: buildCSTagNodeApiValues,
  buildDashletApiValues: buildCSTagNodeDashletApiValues,
  encodeTrendingTagValue,
};
