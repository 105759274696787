import {
  ApiDateFilter,
  DashletApiDateFilters,
  DateCacheKey,
  DateFilterSearch,
  DateRange,
  DateRangeCacheKey,
  MultiTimeviewDateFilter,
  PresetRelativeDay,
  RelativeDateFilter,
  RelativeDateFilterUnit,
  ReportResultsDateFilter,
} from "types";
import {
  addDays,
  addMonths,
  addQuarters,
  addWeeks,
  addYears,
  format,
  nextSunday,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subQuarters,
  subWeeks,
  subYears,
} from "date-fns";
import {
  dayLabelToValues,
  getReportDateFilter,
  parseReportApiCustomFixedDate,
  relativeDateRangeOrNull,
} from "utils/dates/dateFilters";
import { parseFromYmd, parseFromYmdNoDashes } from "utils/dates/dateUtils";

import { DateFormats } from "consts";
import { isNullOrUndefOrEmpty } from "@civicscience/chops";
import { parseNumberOrDefault } from "utils/numbers";

/**
 * Returns correct date values based on user time period selection
 */
export const formatDateFilter = (numDaysAgo: number | string | null): DateRange => {
  const parsed = parseNumberOrDefault(numDaysAgo, null);
  if (parsed === null || parsed < 0) {
    return { startDate: null, endDate: null };
  }

  return {
    startDate: new Date(Date.now() - parsed * 24 * 60 * 60 * 1000),
    endDate: new Date(),
  };
};

const baseStartByUnit: Record<RelativeDateFilterUnit, (d: Date, amount: number) => Date> = {
  days: (d, amount) => subDays(d, amount),
  weeks: (d, amount) => startOfWeek(subWeeks(d, amount)),
  months: (d, amount) => startOfMonth(subMonths(d, amount)),
  quarters: (d, amount) => startOfQuarter(subQuarters(d, amount)),
  years: (d, amount) => startOfYear(subYears(d, amount)),
};

const baseEndByUnit: Record<RelativeDateFilterUnit, (d: Date, amount: number) => Date> = {
  days: (d, amount) => addDays(d, amount),
  weeks: (d, amount) => addWeeks(d, amount),
  months: (d, amount) => addMonths(d, amount),
  quarters: (d, amount) => addQuarters(d, amount),
  years: (d, amount) => addYears(d, amount),
};

/**
 * Converts a custom relative date filter to a date range.
 * This follows the same rules as IS1 where the start date rolls back all the
 * way to the start of that unit of time.
 * For example, when the user requests "a month ago" we actually take the
 * start date back to the _start of_ of the previous month.
 *
 * Weeks follow this exact same pattern when both the period and the "ago" are in weeks.
 * But when the period is a week and the "ago" is NOT a week, then we determine
 * the start date by getting the _next_ Sunday (rather than the previous).
 * For example, if you ask for a week of time that starts a year ago.
 * If January 1 of that year is on a Friday, we will jump ahead to Sunday January 3rd for the start date.
 */
export const relativeDateToDateRange = ({
  periodUnit,
  periodValue,
  relativeUnit,
  relativeValue,
}: RelativeDateFilter): DateRange => {
  const baseStartDate = baseStartByUnit[relativeUnit](new Date(), relativeValue);
  const startDate = periodUnit === "weeks" && relativeUnit !== "weeks" ? nextSunday(baseStartDate) : baseStartDate;
  return {
    startDate,
    endDate: subDays(baseEndByUnit[periodUnit](startDate, periodValue), 1),
  };
};

/**
 * Converts a date filter to a DateRange based on the type of filter that is applied.
 */
export const dateFilterToDateRange = (data: DashletApiDateFilters | null | undefined): DateRange => {
  if (!data) {
    return formatDateFilter(null);
  }

  if (data.customDateFilter) {
    return { startDate: parseFromYmd(data.minDate), endDate: parseFromYmd(data.maxDate) };
  }

  if (data.customRelativeDateFilter) {
    return relativeDateToDateRange(data.customRelativeDateFilter);
  }

  return formatDateFilter(data.daysBefore);
};

/**
 * Converts a date filter to a DateRange based on the type of filter that is applied.
 */
export const dateFilterSearchToDateRange = (data: DateFilterSearch): DateRange => {
  if (data.kind === "live" || data.kind === "all") {
    return { startDate: null, endDate: null };
  }

  if (data.kind === "custom-fixed") {
    return { startDate: data.startDate, endDate: data.endDate };
  }

  return relativeDateToDateRange(data);
};

/**
 * Converts an `ApiDateFilter` to a `DateFilterSearch` instance.
 * Useful, for example, when creating a link from a Dashlet (which uses ApiDateFilter)
 * to the Question Detail page (which uses `DateFilterSearch`).
 *
 * One notable difference is that `DateFilterSearch` does not (yet) support values like "Last 7 days".
 * To handle that case we instead convert it to a date range and set it has a custom-fixed date.
 */
export const apiDateFilterToDateFilterSearch = (data: ApiDateFilter | null | undefined): DateFilterSearch => {
  if (data?.customRelativeDateFilter) {
    return { kind: "custom-relative", ...data.customRelativeDateFilter };
  }

  if (data?.customDateFilter) {
    return { kind: "custom-fixed", startDate: parseFromYmd(data.minDate), endDate: parseFromYmd(data.maxDate) };
  }

  if (data?.daysBefore) {
    return { kind: "custom-fixed", ...formatDateFilter(data.daysBefore) };
  }

  return { kind: "all" };
};

/**
 * Helper to build a stable cache key from a (Date | null) instance.
 * This intentionally only has the precision to the **day** level.
 * This ensures that if dates are being created on render - e.g. `new Date()` -
 * that you are not constantly invalidating the cache.
 *
 * If we need different amounts of precision in the future we can offer that.
 */
export const cacheKeyForDate = (x: Date | null): DateCacheKey => (x ? format(x, DateFormats.Y_M_D) : null);

/**
 * Helper to build a stable cache key from a DateRange instance.
 * See `cacheKeyForDate` for more details.
 */
export const cacheKeyForDateRange = (x: DateRange): DateRangeCacheKey => ({
  startDate: cacheKeyForDate(x.startDate),
  endDate: cacheKeyForDate(x.endDate),
});

export const reportResultsDateFilterToDateRange = (x: ReportResultsDateFilter): DateRange => {
  if (x.dateFilter === "all" || isNullOrUndefOrEmpty(x.dateFilter)) {
    return {
      startDate: null,
      endDate: null,
    };
  }
  if (x.dateFilter === "custom-fixed") {
    return {
      startDate: x.customFixedDateFilter?.minDate,
      endDate: x.customFixedDateFilter?.maxDate,
    };
  }
  if (x.dateFilter === "custom-relative") {
    const dateRange = relativeDateRangeOrNull(x.customRelativeDateFilter);
    if (isNullOrUndefOrEmpty(dateRange)) return { startDate: null, endDate: null };
    else return dateRange;
  }
  return formatDateFilter(dayLabelToValues[x.dateFilter]);
};

/**
 * Converts a report date api filter to a DateRange based on the type of filter that is applied.
 */
export const reportApiDateFilterToDateRange = (data: MultiTimeviewDateFilter): DateRange => {
  const dateFilter = getReportDateFilter(data);
  if (dateFilter === "all" || isNullOrUndefOrEmpty(dateFilter)) {
    return { startDate: null, endDate: null };
  } else if (dateFilter === "custom-fixed" && typeof data === "string") {
    const { minDate, maxDate } = parseReportApiCustomFixedDate(data);
    return { startDate: parseFromYmdNoDashes(minDate), endDate: parseFromYmdNoDashes(maxDate) };
  } else if (typeof data === "string") {
    const daysBefore = dayLabelToValues[dateFilter as PresetRelativeDay] ?? "all";
    return formatDateFilter(daysBefore);
  } else if (dateFilter === "custom-relative" && data) {
    return relativeDateToDateRange(data);
  }

  return { startDate: null, endDate: null };
};
