import CSDangerButton, { CSDangerButtonProps } from "components/shared/button/CSDangerButton";

import React from "react";
import classNames from "classnames";

type CSDeleteButtonProps = Omit<CSDangerButtonProps, "buttonText"> & {
  buttonText?: CSDangerButtonProps["buttonText"];
};

/**
 * Renders a button that is used for a delete button.
 * See https://react-bootstrap.github.io/components/buttons/#api for props.
 */
const CSDeleteButton = ({ className, buttonText = "Delete", ...buttonProps }: CSDeleteButtonProps) => {
  return <CSDangerButton {...buttonProps} className={classNames("cs-delete-btn", className)} buttonText={buttonText} />;
};

export default CSDeleteButton;
