import { QuestionRoleType } from "consts";

const QuestionRoleTypeOptions = [
  {
    type: QuestionRoleType.Engagement,
    text: `Engagement questions capture the attention of your audience
      and encourage participation.`,
  },
  {
    type: QuestionRoleType.Quiz,
    text: `Quiz questions engage your audience by challenging visitors to pick
      the correct answer. The correct answer is revealed in the results.`,
  },
  { type: QuestionRoleType.Value, text: "Value questions gather meaningful information from your audience." },
  { type: QuestionRoleType.Profile, text: "Profile questions build a demographic profile of your audience." },
];

export default QuestionRoleTypeOptions;
