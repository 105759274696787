import { bootIntercomUser, pushToDataLayer } from "utils";

import { User } from "types";
import { envConfig } from "config";
import { isNullOrUndefOrEmpty } from "@civicscience/chops";

const { intercomAppId = "" } = envConfig;

/**
 * Send user specific initialization to analytics platforms
 */
const bootAnalytics = (user: User): void => {
  const { userId, accountRefid: accountId, userInfo, isImpersonated, isSysAdmin, isInternal, account } = user;
  const { isPublisher: isPublisherAccount } = account;
  const { email } = userInfo;
  const userFullName = [user.firstName, user.lastName].filter(Boolean).join(" ");

  // Given intercom is our support system sending email, name, etc IS safe here
  !isNullOrUndefOrEmpty(intercomAppId) &&
    bootIntercomUser({
      appId: intercomAppId,
      userFullName,
      userId,
      email,
      isSysAdmin,
      accountId,
      isPublisherAccount,
    });

  pushToDataLayer({ userId, accountId, isImpersonated, isSysAdmin, isInternal });
};

export default bootAnalytics;
