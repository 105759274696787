import "components/shared/not-found/NotFound.scss";
import { Help } from "consts";
import Obfuscate from "react-obfuscate";
import React from "react";

const NotFound = (): JSX.Element => {
  return (
    <div className="csa-not-found">
      <h1>Not found</h1>
      {
        <p className="csa-not-found-msg">
          The page you were looking for was not found. If you need further assistance accessing your account, email{" "}
          <Obfuscate email={Help.SupportEmail} />
        </p>
      }
    </div>
  );
};

export default NotFound;
