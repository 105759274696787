const Analytics = {
  StartedNewSegment: "started-new-segment",
  FinishedNewSegment: "finished-new-segment",
  StartedNewQuestion: "started-new-question",
  FinishedNewQuestion: "finished-new-question",
  StartedNewDeepProfile: "started-new-deepprofile",
  FinishedNewDeepProfile: "finished-new-deepprofile",
  StartedNewMultiTimeview: "started-new-multitimeview",
  FinishedNewMultiTimeview: "finished-new-multitimeview",
  StartedNewCrosstab: "started-new-crosstab",
  FinishedNewCrosstab: "finished-new-crosstab",
  StartedCrosstabColumnsAndRows: "started-crosstab-columns-and-rows",
  FinishedCrosstabColumnsAndRows: "finished-crosstab-columns-and-rows",
  StartedNewMatrixQuestion: "started-new-matrix-question",
  FinishedNewMatrixQuestion: "finished-new-matrix-question",
  FinishedNewMultiScore: "finished-new-multi-score",
  Home: "view-home",
  QuestionList: "view-question-list",
  QuestionResults: "view-question-results",
  QuestionCompare: "view-question-compare",
  QuestionInsights: "view-question-insights",
  LOGIN: "is2-explicit-login", // User logs themself in
};

export default Analytics;
