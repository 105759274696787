import "components/shared/text/TextSizeDropdown.scss";

import { Dropdown, DropdownProps } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { isKeyValueInLocalStorage, setLocalStorageValue } from "utils";

import CSDropdownMenu from "components/shared/dropdown/CSDropdownMenu";
import CSIcon from "components/shared/icon/CSIcon";
import classNames from "classnames";

type TextSizeType = "sm" | "md" | "lg";

type TextSizeDropdownProps = Omit<DropdownProps, "children"> & {
  text?: string;
  iconClass?: string;
  iconOnly?: boolean;
};

const TextSizeDropdown = ({
  text = "Text Size",
  iconClass = "fa-font",
  iconOnly = true,
  ...dropdownProps
}: TextSizeDropdownProps): JSX.Element => {
  const textSizeStorageKey = "csa-text-size";
  const sizeLg = isKeyValueInLocalStorage(textSizeStorageKey, "lg");
  const sizeSm = isKeyValueInLocalStorage(textSizeStorageKey, "sm");
  const sizeFromStorage: TextSizeType = sizeSm ? "sm" : sizeLg ? "lg" : "md";
  const [textSize, setTextSize] = useState(sizeFromStorage);
  const textSizePrefix = "csa-text-size-";

  useEffect(() => {
    const htmlRoot = document.getElementsByTagName("html")[0];
    htmlRoot.classList.remove(`${textSizePrefix}lg`, `${textSizePrefix}md`, `${textSizePrefix}sm`);

    textSize && htmlRoot.classList.add(`${textSizePrefix}${textSize}`);
  }, [textSize]);

  const onSizeChange = (whichSize: TextSizeType) => {
    setTextSize(whichSize);
    setLocalStorageValue(textSizeStorageKey, whichSize.toString());
  };

  return (
    <Dropdown
      {...dropdownProps}
      drop={"down"}
      className={classNames("csa-text-size-dropdown dropdown-center text-center d-none d-sm-flex", {
        "csa-text-size-dropdown-lg": textSize === "lg",
        "csa-text-size-dropdown-md": textSize === "md",
        "csa-text-size-dropdown-sm": textSize === "sm",
      })}
      align="start"
    >
      <Dropdown.Toggle
        variant="transparent"
        className="csa-text-size-dropdown-toggle rounded-0 border-0"
        aria-label={text}
        title={text}
      >
        <CSIcon icon="fa-solid" className={iconClass} />
        {!iconOnly && <span className="d-none d-sm-inline-block me-sm-auto">{text}</span>}
      </Dropdown.Toggle>
      <CSDropdownMenu className="rounded-0 text-end" rounded={false}>
        <div className="csa-text-size-dropdown-title px-3 py-1 text-end">{text}</div>
        <Dropdown.Item
          className="csa-text-size-dropdown-item-lg"
          eventKey="text-size-lg-option"
          title={`${text} Large`}
          onClick={() => onSizeChange("lg")}
          active={textSize === "lg"}
        >
          Large
        </Dropdown.Item>
        <Dropdown.Item
          className="csa-text-size-dropdown-item-md"
          eventKey="text-size-md-option"
          title={`${text} Medium`}
          onClick={() => onSizeChange("md")}
          active={textSize === "md"}
        >
          Medium
        </Dropdown.Item>
        <Dropdown.Item
          className="csa-text-size-dropdown-item-sm"
          eventKey="text-size-sm-option"
          title={`${text} Small`}
          onClick={() => onSizeChange("sm")}
          active={textSize === "sm"}
        >
          Small
        </Dropdown.Item>
      </CSDropdownMenu>
    </Dropdown>
  );
};

export default TextSizeDropdown;
