import "components/shared/error/AppErrorBoundaryView.scss";

import { debug, isNodeEnvProduction } from "utils";

import { CSApiError } from "@civicscience/chops";
import CSButton from "components/shared/button/CSButton";
import CSFormButtons from "components/shared/form/CSFormButtons";
import Error from "components/shared/error/Error";
import React from "react";

type AppBoundaryEvent = {
  errors: string[];
};

export const onAppBoundaryError = (event: AppBoundaryEvent) => {
  const { errors: appErrors } = event;
  // callback will only run for errors caught by boundary
  debug.error("onAppBoundaryError - boundary error occurred.", { event, appErrors });
};

const appBeginHref = "/";

type AppErrorBoundaryViewProps = {
  error?: CSApiError;
  clearError?: () => void;
};

const AppErrorBoundaryView = ({ error, clearError }: AppErrorBoundaryViewProps): JSX.Element => {
  return (
    <div className="csa-app-error-boundary-view">
      <Error className="csa-app-error-boundary-message" fluid="sm" showIcon={false}>
        <div className="text-center">
          <h1 className="h4">An Error Occurred</h1>
          <div className="mb-2 strong">
            Click `Reset` to try to clear the error,
            <br />
            or click `Go Home` to start from the home page
          </div>
          {error && error.message && !isNodeEnvProduction() && (
            <div className="mb-2 fw-bold">Error Message: {error.message}</div>
          )}
          <CSFormButtons>
            <a href={appBeginHref} className="btn btn-primary btn-sm text-light">
              <i className="csa-i fa-solid fa-house" aria-hidden={true} />
              <span>Go Home</span>
            </a>
            <CSButton.Link
              size="sm"
              onClick={clearError}
              iconLeftClass="fa-solid fa-arrow-rotate-right"
              buttonText="Reset"
            />
          </CSFormButtons>
        </div>
      </Error>
    </div>
  );
};

export default AppErrorBoundaryView;
