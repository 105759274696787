import "components/shared/button/CSSecondaryButton.scss";

import { Button, ButtonProps } from "react-bootstrap";
import React, { ReactNode } from "react";

import CSIconWithText from "components/shared/icon/CSIconWithText";
import { LocationDescriptor } from "history";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { isNullOrUndefOrEmpty } from "@civicscience/chops";

export type CSSecondaryButtonProps = Omit<ButtonProps, "children"> & {
  buttonText: ReactNode;
  isSubmitting?: boolean;
  textClassName?: string;
  iconLeftClass?: string;
  iconRightClass?: string;
  dataTestId?: string;
  isTextVisible?: boolean;
  to?: LocationDescriptor;
  children?: React.ReactNode;
};

const getSecondaryButtonIconClass = (mainBtnClass: string, iconClass?: string) => {
  return isNullOrUndefOrEmpty(iconClass) ? undefined : classNames(mainBtnClass, iconClass);
};

/**
 * Renders a button that is styled for a secondary action.
 * See https://react-bootstrap.github.io/components/buttons/#api for props.
 */
const CSSecondaryButton = ({
  buttonText,
  className,
  textClassName,
  variant = "outline-primary",
  isSubmitting = false,
  isTextVisible = true,
  type = "button",
  iconLeftClass,
  iconRightClass,
  dataTestId,
  as,
  to,
  children,
  ...buttonProps
}: CSSecondaryButtonProps) => {
  if (isNullOrUndefOrEmpty(buttonText)) {
    return null;
  }

  const iconLeftClassName = getSecondaryButtonIconClass("csa-secondary-btn-icon-left", iconLeftClass);
  const iconRightClassName = getSecondaryButtonIconClass("csa-secondary-btn-icon-right", iconRightClass);

  const hasIcon = !isNullOrUndefOrEmpty(iconLeftClassName) || !isNullOrUndefOrEmpty(iconRightClassName);
  /** Object.create(null) instead of {} because the properties of the Object's prototype create TS warnings */
  const optionalLinkProps = to ? { as: NavLink, to } : Object.create(null);
  return (
    <Button
      {...buttonProps}
      disabled={buttonProps.disabled || isSubmitting}
      variant={variant}
      className={classNames("csa-secondary-btn", className, { "csa-secondary-btn-with-icon": hasIcon })}
      type={type}
      as={as}
      data-testid={dataTestId}
      {...optionalLinkProps}
    >
      {isSubmitting && <i className="csa-secondary-btn-spinner csa-i fas fa-sync fa-spin" aria-hidden="true" />}

      <CSIconWithText
        text={buttonText}
        iconLeftClassName={iconLeftClassName}
        iconRightClassName={iconRightClassName}
        textClassName={classNames("csa-secondary-btn-text", textClassName)}
        isTextVisible={isTextVisible}
      >
        {children}
      </CSIconWithText>
    </Button>
  );
};

export default CSSecondaryButton;
