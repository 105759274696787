import { ResponsiveBreakPoints } from "consts";

/**
 * Measures screen innerWidth to decide if screen is above TV screen min width
 */
const isTVScreenWidth = (): boolean => {
  return window.innerWidth > ResponsiveBreakPoints.TV[0];
};

export default isTVScreenWidth;
