import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import { formatDate } from "@civicscience/chops";

/**
 * Used to ensure only the the options you enable are passed to the underlying `formatDate` function.
 */
const emptyFormatOptions = {
  year: undefined,
  month: undefined,
  day: undefined,
  hour: undefined,
  minute: undefined,
  second: undefined,
};

/**
 * Renders a `<time>` tag with formatted text but a machine-readable `dateTime` attribute.
 *
 * Format options are designed to echo DateTimeFormat options:
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat#year
 */
const CSDateFormat = ({ value, className, options }) => {
  // FIXME: Replace with isDateValid from chops while porting to TSX
  const valueAsDate = value instanceof Date ? value : new Date(value);
  const finalOptions = {
    ...emptyFormatOptions,
    ...options,
  };
  return (
    <time className={classNames("csa-date-format", className)} dateTime={valueAsDate.toISOString()}>
      {formatDate(valueAsDate, finalOptions)}
    </time>
  );
};

CSDateFormat.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]).isRequired,
  options: PropTypes.shape({
    year: PropTypes.oneOf(["numeric", "2-digit", undefined]),
    month: PropTypes.oneOf(["numeric", "2-digit", "long", "short", "narrow", undefined]),
    day: PropTypes.oneOf(["numeric", "2-digit", undefined]),
    hour: PropTypes.oneOf(["numeric", "2-digit", undefined]),
    minute: PropTypes.oneOf(["numeric", "2-digit", undefined]),
    second: PropTypes.oneOf(["numeric", "2-digit", undefined]),
  }),
  className: PropTypes.string,
};

export default CSDateFormat;
