import "components/shared/loader/Loader.scss";

import React, { HTMLAttributes, useEffect, useRef, useState } from "react";
import { Spinner, SpinnerProps } from "react-bootstrap";
import { isNullOrUndefOrEmpty, isString, useIsMountedRef } from "@civicscience/chops";

import classNames from "classnames";

type LoaderProps = HTMLAttributes<HTMLDivElement> & {
  loaderMessage?: string | null;
  isAnimated?: boolean;
  delay?: number;
  animation?: "grow" | "border";
  spinnerProps?: Omit<SpinnerProps, "animation">;
};

/**
 * JSX for our basic loading pane that can optionally show a loading message.
 * By default, the loader is not animated.
 * When the @param props.loaderMessage is null, the loader does not show.
 * If it is an empty string (default), the loader can still show with a default message 'Content is loading....'.
 *
 * @param {{
 *  loaderMessage?: string | null,
 *  className?: string,
 *  isAnimated?: boolean,
 *  delay?: number,
 * }} props
 */
const Loader = ({
  loaderMessage,
  className,
  isAnimated = false,
  delay = 100,
  animation = "border",
  spinnerProps,
  ...loaderProps
}: LoaderProps): JSX.Element | null => {
  const [isVisible, setIsVisible] = useState(false);
  const isMountedRef = useIsMountedRef(useRef, useEffect);

  // componentDidUpdate isMountedRef, delay
  useEffect(() => {
    //delayed visibility of the loader
    setTimeout(() => isMountedRef.current && setIsVisible(true), delay);
  }, [isMountedRef, delay]);

  if (!isString(loaderMessage)) {
    return null;
  }

  return (
    <div
      {...loaderProps}
      className={classNames("csa-loader", className, {
        "csa-loader-animated": isAnimated,
        "csa-loader-visible": isVisible,
      })}
      role="alert"
      aria-busy="true"
    >
      {isNullOrUndefOrEmpty(loaderMessage) ? (
        <div className="visually-hidden">Content is loading....</div>
      ) : (
        <div className="csa-loader-text" title={loaderMessage}>
          {loaderMessage}
        </div>
      )}
      {isAnimated && (
        <div className="csa-loader-anim-wrap" aria-hidden="true">
          <Spinner className="csa-loader-anim" animation={animation} {...spinnerProps} />
        </div>
      )}
    </div>
  );
};

export default Loader;
