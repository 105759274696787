import { PostHog } from "posthog-js/react";
import { UserWithPermissions } from "types";

const getAccount = (user: UserWithPermissions) => ({
  accountId: user.account.accountId,
  name: user.account.name,
  accountType: user.account.accountType,
  updatedTime: user.account.updatedTime,
});

/**
 * Bootstraps Posthog with user data.
 */
const bootPosthog = (posthog: PostHog, user: UserWithPermissions) => {
  const { accountId, ...account } = getAccount(user);
  const name = [user.firstName, user.lastName].filter(Boolean).join(" ");

  posthog.identify(user.userId.toString(), {
    name,
    email: user.userInfo.email,
    isSysAdmin: user.isSysAdmin,
    isInternal: user.isInternal,
    isImpersonated: user.isImpersonated,
    dateRegistered: user.userInfo.dateRegistered,
    account: { accountId, ...account },
  });

  // posthog groups are an upgraded subscription feature
  posthog.group("company", accountId.toString(), account);
};

export default bootPosthog;
