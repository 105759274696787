import { ObjectValues } from "types";

/**
 * Maps to account/permissions/UserPermissionTypes on the backend.
 */
const UserPermissionTypes = {
  QUESTION_COMMENTS: "question:comments",
  QUESTION_CREATE: "question:create",
  QUESTION_CLONE: "question:clone",
  QUESTION_CREATE_IN_GLOBAL_NETWORK: "question:create-in-global-network",
  QUESTION_CREATE_SPONSORED: "question:create-sponsored",
  DEEP_PROFILE_CREATE: "deep-profile:create",
  SYSADMIN: "sysadmin:menu", // admin menu
  TARGET_LIST: "target:list",
  TARGET_CREATE: "target:create",
  TARGET_CLONE: "target:clone",
  TARGET_QUESTION_FEEDS: "target:question_feeds",
  TARGET_QUESTION_SOURCES: "target:question_sources",
  QUESTION_FEED_LIST: "question-feed:list",
  QUESTION_FEED_CREATE: "question-feed:create",
  USER_CREATE: "user:create",
  USERGROUP_CREATE: "usergroup:create",
  NETWORK_LIST: "network:list",
  NETWORK_CREATE: "network:create",
  DEFAULT_SETTINGS_EDIT: "default-settings:edit",
  QUESTION_TARGET_FILTER: "question:target-filter",
  SCORE_SHARE_TO_GLOBAL_NETWORK: "score:share-to-global-network",
  ACCOUNT_PROPERTIES: "account:properties-view",
  REPORT_FILES: "report:file-view",
  MULTI_SCORE_TARGET_FILTER: "multi-score:target-filter", // TODO deprecated. delete me
} as const;

export type UserPermissionType = ObjectValues<typeof UserPermissionTypes>;

export default UserPermissionTypes;
