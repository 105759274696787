import CSSubmitButton, { CSSubmitButtonProps } from "components/shared/button/CSSubmitButton";
import React, { ReactNode } from "react";

import classNames from "classnames";

type CSCreateButtonProps = Omit<CSSubmitButtonProps, "buttonText"> & {
  buttonText?: ReactNode;
  isTextVisible?: boolean;
  isIconVisible?: boolean;
};

/**
 * Renders a button that is used for a create button.
 * See https://react-bootstrap.github.io/components/buttons/#api for props.
 */
const CSCreateButton = ({
  className,
  type = "button",
  variant = "primary",
  buttonText = "Create",
  isSubmitting = false,
  isTextVisible = true,
  isIconVisible = true,
  iconLeftClass = "fa fa-plus",
  ...buttonProps
}: CSCreateButtonProps) => {
  return (
    <CSSubmitButton
      {...buttonProps}
      type={type}
      variant={variant}
      className={classNames("cs-create-btn", className)}
      disabled={isSubmitting}
      isSubmitting={isSubmitting}
      buttonText={buttonText}
      isTextVisible={isTextVisible}
      iconLeftClass={isIconVisible ? iconLeftClass : undefined}
    />
  );
};

export default CSCreateButton;
