import { formatDate, isDateValid, isNullOrUndefOrEmpty } from "@civicscience/chops";

import { DateFormats } from "consts";
import { parse } from "date-fns";

/**
 * Values that can represent a Date - e.g. can be passed to the Date constructor.
 */
export type DateLike = Date | string | number;

/**
 * Attempts to parse an input to a Date instance.
 * This handles a variety of inputs that can representa a date in JS - which we call `DateLike`.
 * If the input does not produce a valid date then the `fallback` will be returned.
 */
export const parseDateLike = <T = null>(d: DateLike | null | undefined, fallback: T | null = null): Date | null | T => {
  if (isDateValid(d)) {
    return d;
  }

  if (typeof d === "number" || typeof d === "string") {
    const result = new Date(d);
    return isDateValid(result) ? result : fallback;
  }

  return fallback;
};

/**
 * Small wrapper over date-fns `parse` to parse a formatted string to a Date instance.
 *
 * See `parseFromYmd` and `parseFromYmdNoDashes` below for more detail.
 */
const parseByFormat = <T = null>(
  dateString: string | null,
  formatString: string,
  fallback: T | null = null,
): Date | T | null => {
  if (isNullOrUndefOrEmpty(dateString)) {
    return fallback;
  }

  const parsedDate = parse(dateString, formatString, new Date());
  return isDateValid(parsedDate) ? parsedDate : fallback;
};

/**
 * Parses a string in the YMD format (e.g. "2023-01-31") to a Date instance.
 *
 * This is for specific cases where the API returns dates in YMD format
 * and we want the browser to interpret it as a _local_ date.
 * Typically, the browser would convert a string in this format to the local timezone.
 * For example, the input of "2023-01-01" will return a Date instance of Jan 1 2023
 * regardless of the timezone/locale of the machine running the code.
 *
 * See the tests for further examples.
 */
export const parseFromYmd = <T = null>(dateString: string | null, fallback: T | null = null): Date | T | null => {
  return parseByFormat(dateString, DateFormats.Y_M_D, fallback);
};

/**
 * Parses a string in the YMD format with no dashes (e.g. "20230131") to a Date instance.
 *
 * See `parseFromYmd` for more detail about why/when both functions are useful.
 */
export const parseFromYmdNoDashes = <T = null>(
  dateString: string | null,
  fallback: T | null = null,
): Date | T | null => {
  return parseByFormat(dateString, DateFormats.Y_M_D_NO_DASH, fallback);
};

/**
 * When a c-results API expects a start and end date it will NOT accept only a start date.
 * In the PHP backend, the logic ignores date ranges that contain only a start date.
 * Until that is fixed, this side steps that logic by providing an end date if only a start date is provided.
 *
 * See: https://civicscience.atlassian.net/browse/SOF-3145
 */
export const normalizeEndDate = (startDate: Date | null | undefined, endDate: Date | null | undefined) =>
  endDate ? endDate : startDate ? new Date() : undefined;

type FormatDateAndTimeProps = {
  date?: DateLike | null;
  dateOptions?: Intl.DateTimeFormatOptions;
  timeOptions?: Intl.DateTimeFormatOptions;
};
export const formatDateAndTime = ({
  date,
  dateOptions = { month: "short", day: "2-digit", year: "numeric" },
  timeOptions = { hour: "2-digit", minute: "2-digit", second: "2-digit", timeZoneName: "short" },
}: FormatDateAndTimeProps) => {
  const dateToFormat = date ? new Date(date) : new Date();
  const formatTimeShort = new Intl.DateTimeFormat("en", timeOptions).format;

  const returnDate = formatDate(dateToFormat.toString(), dateOptions);
  const returnTime = formatTimeShort(dateToFormat);

  return {
    date: returnDate,
    time: returnTime,
  };
};

export const dateDiffInDays = (startDate: Date | null, endDate: Date | null) => {
  const msPerDay = 1000 * 60 * 60 * 24;

  if (isNullOrUndefOrEmpty(startDate) || isNullOrUndefOrEmpty(endDate)) return 0;

  // Discard the time and time-zone information.
  const utc1 = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
  const utc2 = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());

  return Math.floor((utc2 - utc1) / msPerDay);
};
