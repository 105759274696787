/**
 NOTE: .csa-form-btns style class is defined in styles/partials/_forms.scss
 */

import React, { HTMLAttributes } from "react";

import classNames from "classnames";
import { isNullOrUndefOrEmpty } from "@civicscience/chops";

type CSFormButtonsProps = HTMLAttributes<HTMLDivElement> & {
  alignment?: "start" | "center" | "end";
};

/**
 * Creates a wrapper for our form buttons with our "csa-form-btns" className.
 * NOTE: Use CSFormLayout.Buttons if the form buttons are the most common (Delete, Cancel, Reset, Submit)
 */
const CSFormButtons = ({ alignment, className, children, ...divProps }: CSFormButtonsProps): JSX.Element => {
  const alignmentClass = isNullOrUndefOrEmpty(alignment) ? undefined : `text-${alignment}`;

  return (
    <div className={classNames("csa-form-btns", className, alignmentClass)} {...divProps}>
      {children}
    </div>
  );
};

export default CSFormButtons;
