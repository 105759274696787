import { RemoteDataSimple } from "types";

export const STALE_3_MINUTES = 1000 * 60 * 3;

/**
 * Default options when loading data for charts.
 */
export const defaultChartQueryOptions = {
  // allows highcharts to animate from old -> new data after a fetch
  keepPreviousData: true,
  // chart data API calls are typically expensive so we normally skip re-requesting for a few minutes.
  staleTime: STALE_3_MINUTES,
};

export const defaultQuestionCompareOptions = {
  /** TODO check common data */ keepPreviousData: true,
  staleTime: STALE_3_MINUTES,
};

/**
 * Merges 2 queries into 1 object with a single `isLoading`, `error`, and `data` properties.
 *
 * isLoading - set to true if either query is loading
 * error - set to the first error (if any) encountered
 * data - set to the refined values once both are available (otherwise undefined)
 */
export const mergeQueries = <T, U>(
  queries: [RemoteDataSimple<T>, RemoteDataSimple<U>],
): {
  isLoading: boolean;
  error: unknown;
  data: [T, U] | undefined;
} => {
  return {
    isLoading: queries.some((x) => x.isLoading),
    error: queries.find((x) => !!x.error),
    data: queries[0].data && queries[1].data ? [queries[0].data, queries[1].data] : undefined,
  };
};
