import "components/shared/footer/AppFooter.scss";

import React, { HTMLAttributes } from "react";

import CSDateFormat from "components/shared/date/CSDateFormat";
import CSLink from "components/shared/links/CSLink";
import { Help } from "consts";
import LinkToNewTab from "components/shared/links/LinkToNewTab";
import classNames from "classnames";
import { routePaths } from "routing";

/**
 * Renders just the year portion of the current date.
 */
const CurrentYear = () => <CSDateFormat value={new Date()} options={{ year: "numeric" }} />;

/**
 * Renders the copyright text for the footer.
 */
const InsightStoreCopyright = (): JSX.Element => (
  <>
    <span className="csa-app-footer-is me-1">
      <strong>Insight</strong>
      Store
    </span>
    &copy; <CurrentYear />, CivicScience
  </>
);

type AppFooterProps = HTMLAttributes<HTMLDivElement> & {
  classNameDefaults?: string;
  showTermsPrivacy?: boolean;
};

/**
 * Renders the application footer.
 */
const AppFooter = ({
  className,
  classNameDefaults = "py-3",
  showTermsPrivacy = true,
  ...divProps
}: AppFooterProps): JSX.Element => {
  return (
    <footer className={classNames("csa-app-footer", className, classNameDefaults)} {...divProps}>
      <ul className="csa-app-footer-nav">
        <li>
          <LinkToNewTab href={Help.HelpUrl}>Help &amp; FAQ</LinkToNewTab>
        </li>
        <li>
          <LinkToNewTab href={Help.ContactUsUrl}>Contact Us</LinkToNewTab>
        </li>
        {showTermsPrivacy && (
          <>
            <li>
              <LinkToNewTab href="https://civicscience.com/privacy-policy/">Privacy Policy</LinkToNewTab>
            </li>
            <li>
              <CSLink to={routePaths.TERMS_OF_USE}>Terms and Conditions</CSLink>
            </li>
          </>
        )}
        <li>
          <InsightStoreCopyright />
        </li>
      </ul>
    </footer>
  );
};

export default AppFooter;
