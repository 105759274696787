import { ObjectValues } from "types";

const AuthStatus = {
  InitStatus: "init",
  SuccessStatus: "success",
  ErrorStatus: "error",
  PendingStatus: "pending",
} as const;

export type AuthStatusTypes = ObjectValues<typeof AuthStatus>;

export default AuthStatus;
