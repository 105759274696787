import { RemoteDataSimple, TaxonomyNode, TaxonomyNodeApi } from "types";
import csTagNodeUtils, { CSTagNodeValue } from "utils/taxonomy/csTagNodeUtils";
import taxonomyNodeUtils, { TaxonomyNodeValue } from "utils/taxonomy/taxonomyNodeUtils";

import classNames from "classnames";
import { partition } from "utils/base";

export const encodeTrendingTagValue = csTagNodeUtils.encodeTrendingTagValue;

/**
 * Either a CSTagNodeValue or TaxonomyNodeValue.
 * Both are strings, but each encodes path data particular to that type of node.
 */
type NodeValue = CSTagNodeValue | TaxonomyNodeValue;

/**
 * Helper function to map a tree of `TaxonomyNodeApi` nodes to a tree of `TaxonomyNode` nodes.
 */
const mapFromApi = (
  nodes: TaxonomyNodeApi[],
  mapFn: (n: TaxonomyNodeApi, parent?: TaxonomyNode) => Omit<TaxonomyNode, "children">,
  parent?: TaxonomyNode,
): TaxonomyNode[] => {
  return nodes.map((node) => {
    const { nodes } = node;
    const newNode = mapFn(node, parent);
    return {
      ...newNode,
      children: nodes && nodes.length > 0 ? mapFromApi(nodes, mapFn, newNode) : undefined,
    };
  });
};

/**
 * Maps the API response to the shape needed by the client app.
 * This expects the caller to separate out the taxonomy nodes from the
 * current account tags & cs tags as we will process them differently.
 *
 * TODO: Determine naming pattern for regular tags. Remove `cs` prefix from
 * all underlying code? Including `csTagNodeUtils.ts`
 */
export const mapToTaxonomyNodes = (
  taxonomyApiNodes: TaxonomyNodeApi[],
  tagApiNodes: TaxonomyNodeApi[],
): TaxonomyNode[] => {
  // The current account tags is at index 0 if theres more than 1 tagApiNodes.
  // The last tag is always Civicscience Tags.
  const currentAccountTags = tagApiNodes.length > 1 ? [tagApiNodes[0]] : [];
  const csTags = tagApiNodes.slice(-1);

  return [
    ...mapFromApi(taxonomyApiNodes, (n, parent) => ({
      value: taxonomyNodeUtils.encodeValue(parent, n),
      label: n.name,
      showCheckbox: !!n.taxonomyNodeId,
    })),
    ...mapFromApi(currentAccountTags, (n, parent) => ({
      value: csTagNodeUtils.encodeValue(parent, n),
      label: n.name,
      showCheckbox: !!n.taxonomyNodeId,
    })),
    ...mapFromApi(csTags, (n, parent) => ({
      value: csTagNodeUtils.encodeValue(parent, n, true),
      label: n.name,
      showCheckbox: !!n.taxonomyNodeId,
    })),
  ];
};

/**
 * Determines the "selections" that should be displayed based off the full tree
 * as well as the "checked" nodes.
 *
 * This implementation expects that the `checked` array only includes a parent node
 * when all of its children are also checked.
 * You can get this behavior from `react-checkbox-tree` by setting `checkModel` to "all".
 * Our wrapped version does this out of the box.
 *
 * Optimization thoughts:
 * - We really only need to search one root level selectable node per call.
 *   For example if you click on `1:26:35` we could start this search at `1` (I think).
 * - Recursion may be a bad idea in here - possible to stack overflow?
 * - Maybe could track a copy of `checked` that we deplete as we build the pills
 *   maybe we can exit early once all "checked" entries are accounted for
 *   thinking this could save us from walking other branches if we handle all checked early on
 */
export const buildSelections = (
  nodes: TaxonomyNode[],
  checked: string[],
  result: TaxonomyNode[] = [],
): TaxonomyNode[] => {
  nodes.forEach((node) => {
    if (checked.includes(node.value)) {
      return result.push(node);
    } else {
      buildSelections(node.children ?? [], checked, result);
    }
  });
  return result;
};

const isNodeMatch = (node: TaxonomyNode, loweredFilterText: string): boolean =>
  !!node.label?.toString()?.toLowerCase().includes(loweredFilterText);

/**
 * Filter the tree by applying CSS.
 *
 * This results in _the exact same nodes_ we just apply className based on how they match the search text.
 * We maintain the same nodes so that selections within the underlying tree control are preserved.
 * If you give the tree control a filtered set and someone makes a selection, then the "checked"
 * array provided by the tree only includes selections within the filtered set.
 * While we could probably write code to handle that, it seems simpler to just maintain the full
 * set of nodes and visually hide the ones that are not matched.
 *
 * We apply the following classes when filtering is present:
 *
 * `d-none` - node and ALL children do not match, hide it
 * `visible` - node or at least one child matches, display it
 * `partial-match` - node itself does not match, but at least one child does
 *                   this is applied _in addition_ to `visible` and allows us to style partial matches in the UI
 *
 * This can most likely be optimized but we plan to debounce calls to it.
 * We can see how it performs and adjust from there.
 *
 * One thought is that we re-check children for visibility to determine if the parent is visible.
 * If we switched this to a reduce call we could track the visibility along the way
 * and continuously roll it up to a single value.
 * Again, we can see how this performs and decide if that is necessary.
 */
export const filterNodesByCss = (nodes: TaxonomyNode[], filterText: string): [boolean, TaxonomyNode[]] => {
  if (!filterText || filterText.length <= 1) {
    return [true, nodes];
  }

  const lowerFilter = filterText.toLocaleLowerCase();

  const checkVisible = (node: TaxonomyNode): TaxonomyNode => {
    // ## Leaf nodes...
    if (!node.children || node.children.length === 0) {
      // only return true here if the filter text matches
      const isMatch = isNodeMatch(node, lowerFilter);
      const className = classNames({
        visible: isMatch,
        "d-none": !isMatch,
      });
      return { ...node, className };
    }

    // ## Nodes with children

    // first calculate the visibility of the children
    const children = node.children.map(checkVisible);

    // if this node matches the search or at least one child - show it
    // short circuit on this node matching in case can skip looping the children again
    const isMatch = isNodeMatch(node, lowerFilter);
    const isVisible = isMatch || children.some((x) => x.className?.includes("visible"));
    const className = classNames({
      visible: isVisible,
      "partial-match": !isMatch && isVisible,
      "d-none": !isVisible,
    });
    return { ...node, className, children };
  };

  const filteredNodes = nodes.map(checkVisible);
  const areNodesVisible = !!filteredNodes.find((x) => x.className?.includes("visible"));
  return [areNodesVisible, filteredNodes];
};

/**
 * Translates the `NodeValue[]` into an array of unique taxonomy tag IDs.
 * We exclude any "IDs" that are NOT numbers.
 * This accounts for the top 2 levels of the hierarchy which are not actually taxonomy nodes
 * and do not have valid IDs in the system. They merely exist as organization for the tree,
 * but are not used to tag content itself.
 *
 * An example NodeValue of `vertical.civic topics.3139` will return `[3139]`
 */
export const buildApiValues = (selectedTags: NodeValue[]) => {
  const [csTags, taxonomyTags] = partition(selectedTags, csTagNodeUtils.isTagNodeValue);

  return {
    taxonomyTags: taxonomyNodeUtils.buildApiValues(taxonomyTags),
    tags: csTagNodeUtils.buildApiValues(csTags),
  };
};

/**
 * Builds `{ placeholder, disabled }` based on the provided `RemoteDataSimple`.
 */
export const getLoadingProps = (remoteData: RemoteDataSimple) => {
  if (remoteData.isLoading) {
    return { disabled: true, placeholder: "Loading tags..." };
  }

  if (remoteData.error) {
    return { disabled: true, placeholder: "Error loading tags" };
  }

  return { disabled: false, placeholder: undefined };
};

/**
 * Returns the top level taxonomy nodes that are typically initially opened.
 */
export const getInitialExpanded = () => ["verticals", "consumer profile"];
