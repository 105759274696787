import { ResponsiveBreakPoints } from "consts";

/**
 * Measures screen innerWidth to decide if screen is above desktop screen min width and below TV screen width
 */
const isDesktopScreenWidth = (): boolean => {
  return window.innerWidth > ResponsiveBreakPoints.Desktop[0] && window.innerWidth < ResponsiveBreakPoints.Desktop[1];
};

export default isDesktopScreenWidth;
