import React, { ReactNode } from "react";

import CSIcon from "components/shared/icon/CSIcon";
import classNames from "classnames";
import { isNullOrUndefOrEmpty } from "@civicscience/chops";

type CSIconWithTextProps = {
  text: ReactNode;
  iconLeftClassName?: string;
  iconRightClassName?: string;
  textClassName?: string;
  isTextVisible?: boolean;
  children?: ReactNode;
};

const CSIconWithText = ({
  text,
  iconLeftClassName,
  iconRightClassName,
  textClassName,
  isTextVisible = true,
  children,
}: CSIconWithTextProps): JSX.Element => {
  const hasIcon = !isNullOrUndefOrEmpty(iconLeftClassName) || !isNullOrUndefOrEmpty(iconRightClassName);

  return (
    <>
      {iconLeftClassName && <CSIcon icon={iconLeftClassName} />}

      <span
        className={classNames(textClassName, {
          "visually-hidden": !isTextVisible,
          "csa-i-text": hasIcon,
          "csa-no-i-text": !hasIcon,
        })}
      >
        {text}
      </span>

      {children}

      {iconRightClassName && <CSIcon icon={iconRightClassName} />}
    </>
  );
};

export default CSIconWithText;
