import { ObjectValues } from "types";

const SharedQuestionTerms = {
  TRENDING: "trending",
  TRACKING: "tracking",
  CYCLICAL: "cyclical",
  ARCHIVED: "archived",
  FAVORITES: "favorites",
} as const;

export type SharedQuestionTerm = ObjectValues<typeof SharedQuestionTerms>;

export default SharedQuestionTerms;
