import { ResponsiveBreakPoints } from "consts";

/**
 * Measures screen innerWidth to decide if screen is within mobile widths (including tablet)
 */
const isTabletScreenWidth = (): boolean => {
  return window.innerWidth > ResponsiveBreakPoints.Tablet[0] && window.innerWidth < ResponsiveBreakPoints.Tablet[1];
};

export default isTabletScreenWidth;
