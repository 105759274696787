import React, { HTMLAttributes } from "react";

import { Link } from "react-router-dom";
import { LocationDescriptor } from "history";
import classNames from "classnames";
import { isNullOrUndefOrEmpty } from "@civicscience/chops";

type CSLinkProps = HTMLAttributes<HTMLAnchorElement> & {
  href?: string;
  target?: string;
  to?: LocationDescriptor;
};
/**
 * Renders an anchor.
 */
const CSLink = ({ href, children, className, target, to, ...anchorProps }: CSLinkProps): JSX.Element | null => {
  const hasHref = !isNullOrUndefOrEmpty(href);
  const hasTo = !isNullOrUndefOrEmpty(to);
  const rel = target ? "noopener noreferrer" : undefined;
  const linkProps = { rel, target, className: classNames("cs-link link", className), ...anchorProps };

  return hasHref ? (
    <a href={href} {...linkProps}>
      {children}
    </a>
  ) : hasTo ? (
    <Link to={to} {...linkProps}>
      {children}
    </Link>
  ) : null;
};

export default CSLink;
