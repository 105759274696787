/**
 * Script provided by Intercom to initialize the client and widget.
 *
 * Separated into its own file so it can remain JS.
 *
 * @param {string} appId - Intercom App Id
 */
export const initializeIntercom = (appId) => {
  const win = window;
  const icom = win.Intercom;
  if (typeof icom === "function") {
    icom("reattach_activator");
    icom("update", win.intercomSettings);
  } else {
    const doc = document;
    const fn = function () {
      fn.c(arguments);
    };
    fn.q = [];
    fn.c = function (args) {
      fn.q.push(args);
    };
    win.Intercom = fn;
    const l = function () {
      const s = doc.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = "https://widget.intercom.io/widget/" + appId;
      const x = doc.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
    };
    if (win.attachEvent) {
      win.attachEvent("onload", l);
    } else {
      win.addEventListener("load", l, false);
    }
  }
};
