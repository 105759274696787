import "components/shared/logo/Logo.scss";

import React, { HTMLAttributes } from "react";

import classNames from "classnames";
import { isNullOrUndefOrEmpty } from "@civicscience/chops";
import logoBlackAndWhite from "images/cs-logo-black-and-white.svg";
import logoColor from "images/cs-logo.svg";

type LogoProps = HTMLAttributes<HTMLImageElement> & {
  alt?: string;
  size?: "" | "xs" | "sm" | "md" | "lg" | "xl";
  logoType?: "color" | "black-and-white";
};

const Logo = ({
  className,
  alt = "CivicScience logo",
  size = "",
  logoType = "black-and-white",
  ...restProps
}: LogoProps): JSX.Element => {
  const logoSizeClassName = !isNullOrUndefOrEmpty(size) ? `csa-logo-${size}` : null;
  const logo = logoType === "black-and-white" ? logoBlackAndWhite : logoColor;
  return <img src={logo} className={classNames("csa-logo", className, logoSizeClassName)} alt={alt} {...restProps} />;
};

export default Logo;
