import "components/UnAuthenticatedApp.scss";

import React, { Suspense, useEffect, useRef, useState } from "react";
import { Redirect, Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { getRandomItem, isNullOrUndefOrEmpty } from "@civicscience/chops";
import { protectedRoutes, routePaths, unprotectedRoutes, useRouterScrollTop } from "routing";

import { Insight } from "api/services/InsightsAPI";
import { InsightsAPI } from "api";
import Loader from "components/shared/loader/Loader";
import NotFound from "components/shared/not-found/NotFound";
import SafeHtml from "components/shared/html/SafeHtml";
import { envConfig } from "config";
import { useQuery } from "react-query";

const UnAuthenticatedApp = () => {
  const scrollTopRef = useRef(null);

  const { publicUrl } = envConfig;

  const [currentInsight, setCurrentInsight] = useState<Insight | null>(null);

  const insights = useQuery(["insights", "list"], () => InsightsAPI.list());

  // Utilizing window.location here rather than useLocation due to being outside the scope of the <Router>
  const queryParams = window.location.search;

  useEffect(() => setCurrentInsight(getRandomItem(insights.data ?? [])), [insights.data, setCurrentInsight]);

  useRouterScrollTop({ isMountOnly: true, scrollNodeRef: scrollTopRef, canScroll: true });

  return (
    <Router basename={publicUrl}>
      <div className="csa-app csa-unauth-app" ref={scrollTopRef} tabIndex={-1}>
        <main className="csa-app-main">
          <Suspense fallback={<Loader isAnimated={true} />}>
            <div className="csa-unauth d-flex">
              <div className="csa-unauth-form-section bg-light shadow">
                <Switch>
                  <Route exact path={routePaths.BASE}>
                    <Redirect to={routePaths.LOGIN + queryParams} />
                  </Route>
                  {unprotectedRoutes.map((route, i) => (
                    <Route path={route.path} component={route.component} key={`csa-unauth-route_${i}`} />
                  ))}
                  {protectedRoutes.map((route, i) => (
                    <Route path={route.path} key={`csa-auth-route_${i}`}>
                      <Redirect to={routePaths.LOGIN + queryParams} />
                    </Route>
                  ))}
                  <Route>
                    <NotFound />
                  </Route>
                </Switch>
              </div>
              <div className="csa-unauth-marketing bg-light-medium">
                {currentInsight ? (
                  <div className="csa-unauth-marketing-jumbotron jumbotron-fluid mx-auto">
                    <div className="csa-unauth-marketing-lead text-muted mb-3">Did you know?</div>
                    {!isNullOrUndefOrEmpty(currentInsight.insight) && (
                      <SafeHtml className="csa-unauth-marketing-title text-tertiary" html={currentInsight.insight} />
                    )}
                    {!isNullOrUndefOrEmpty(currentInsight.qtext) && (
                      <div className="csa-unauth-marketing-body">
                        <span>When asked,&nbsp;</span>
                        <span className="fst-italic">
                          &quot;
                          <SafeHtml as="span" html={currentInsight.qtext} />
                          &quot;
                        </span>
                      </div>
                    )}
                  </div>
                ) : (
                  <Loader isAnimated={true} loaderMessage="Fetching Insights" />
                )}
              </div>
            </div>
          </Suspense>
        </main>
      </div>
    </Router>
  );
};

export default UnAuthenticatedApp;
