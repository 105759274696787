import "components/shared/navigation/AppHeaderNavbar.scss";

import { Container, Nav, Navbar } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import {
  ThemeColors,
  getRandomItem,
  isNullOrUndefOrEmpty,
  isString,
  scrollToNode,
  toObject,
} from "@civicscience/chops";
import { buildUserDisplayName, useAdaptiveHelpers } from "utils";

import CSButton from "components/shared/button/CSButton";
import OrganizationView from "components/profile/organization/OrganizationView";
import TextSizeDropdown from "components/shared/text/TextSizeDropdown";
import { User } from "types";
import classNames from "classnames";
import { routePaths } from "routing";
import { useAuthState } from "context/AuthContext";

const ThemeHeaderIconColors = [
  ThemeColors.Secondary,
  ThemeColors.Quaternary,
  ThemeColors.Excite,
  ThemeColors.Royale,
  ThemeColors.Danger,
  ThemeColors.Info,
  ThemeColors.WarningDark,
  ThemeColors.Organa,
  ThemeColors.SuccessDark,
  ThemeColors.ExciteDark,
  ThemeColors.DangerDark,
  ThemeColors.InfoDark,
];

//NOTE: userObject will need to be a top level Type that is used by this component and AuthContext
const buildUserInitials = (userObject: { firstName?: string; lastName?: string }): string => {
  const { firstName, lastName } = userObject;
  const firstInitial = !isNullOrUndefOrEmpty(firstName) && isString(firstName) ? firstName.charAt(0) : "";
  const lastInitial = !isNullOrUndefOrEmpty(lastName) && isString(lastName) ? lastName.charAt(0) : "";

  return firstInitial + lastInitial;
};

//TODO: currentUser should be of User type from context/AuthContext
type AppHeaderUserGreetingProps = {
  currentUser: User;
};

const AppHeaderUserGreeting = ({ currentUser }: AppHeaderUserGreetingProps): JSX.Element | null => {
  if (isNullOrUndefOrEmpty(currentUser)) {
    return null;
  }

  const displayName = buildUserDisplayName(currentUser);
  const { firstName } = toObject(currentUser);
  const title = firstName ? firstName : displayName ? displayName : null;

  return (
    <div className="csa-app-header-hello fw-bold">
      Hey
      {!isNullOrUndefOrEmpty(title) && <span className="d-none d-md-inline">, {title}</span>}
      <span className="d-inline">!</span>
    </div>
  );
};

const AppHeaderNavbar = ({ ...navbarProps }): JSX.Element => {
  const location = useLocation();
  const { currentUser } = useAuthState();

  const appNavEndRef = useRef<HTMLDivElement>(null);
  const userInitials = buildUserInitials(currentUser);
  const [iconBackgroundClass, setIconBackgroundClass] = useState("");
  const { isMobile } = useAdaptiveHelpers();

  useEffect(() => {
    isNullOrUndefOrEmpty(iconBackgroundClass) &&
      setIconBackgroundClass("bg-" + (getRandomItem(ThemeHeaderIconColors) || ThemeHeaderIconColors[0]));
  }, [iconBackgroundClass]);

  const skipNavigation = (scrolling = false) => {
    const appNavEndNode = appNavEndRef && appNavEndRef.current;
    if (appNavEndNode) {
      scrolling && scrollToNode(appNavEndNode);
      appNavEndNode.focus();
    }
  };

  /**
   * NOTE: a uniquely named eventKey is a hack needed the Navbar to collapseOnSelect when a NavLink is used.
   * https://stackoverflow.com/questions/54859515/react-bootstrap-navbar-collapse-not-working/56485081#56485081
   */

  return (
    <>
      <Navbar
        {...navbarProps}
        className="csa-app-header-navbar p-0 text-dark shadow"
        expand="sm"
        bg={isMobile ? "dark" : "light"}
        variant={isMobile ? "dark" : "light"}
        collapseOnSelect
        onSelect={() => skipNavigation(false)}
      >
        <Container fluid={true} className="csa-app-header-navbar-container p-0">
          <CSButton.Primary
            className="csa-skip-nav visually-hidden"
            onClick={() => skipNavigation(true)}
            buttonText="Skip Navigation"
          />

          <Nav className="justify-content-sm-between w-100" activeKey={location.pathname}>
            <div className="csa-app-header-nav-r ms-sm-auto">
              <TextSizeDropdown />
              <div className="csa-app-header-nav-block">
                <div className="csa-app-header-user-info my-auto">
                  <div className={classNames("csa-app-header-user-icon", iconBackgroundClass)}>
                    {!isNullOrUndefOrEmpty(userInitials) && (
                      <span className="csa-app-header-user-initials">{userInitials}</span>
                    )}
                  </div>
                  <div className="csa-app-header-user-detail">
                    <AppHeaderUserGreeting currentUser={currentUser} />

                    <Nav.Link
                      eventKey={routePaths.LOGOUT}
                      as={NavLink}
                      to={routePaths.LOGOUT}
                      className="csa-app-header-logout"
                    >
                      <span className="me-2">Logout</span>
                      <i className="fa-light fa-arrow-right-from-bracket" />
                    </Nav.Link>
                  </div>
                </div>
              </div>
              <div className="csa-app-header-nav-block d-none d-sm-flex">
                <OrganizationView
                  className="csa-app-header-account-info csa-app-header-nav-dropdown"
                  menuClassName="csa-app-header-account-info-menu"
                />
              </div>
            </div>
          </Nav>
        </Container>
      </Navbar>
      <div className="csa-app-header-nav-end" ref={appNavEndRef} tabIndex={-1} />
    </>
  );
};

export default AppHeaderNavbar;
