import { ProviderShape, SupportedContext, UseContext } from "types";
import React, { ReactNode, createContext, useContext } from "react";

import CSError from "components/shared/error/Error";
import { UsePageFeedbackResult } from "utils/hooks/usePageFeedback/usePageFeedback";
import { isNullOrUndef } from "@civicscience/chops";

export type CSProviderProps<T extends SupportedContext, K extends ProviderShape<T>> = {
  children: React.ReactNode;
  Context: React.Context<T | undefined>;
  args: K;
  requiredKeys: (keyof K)[]; // can make this optional if use case arises
  feedback: UsePageFeedbackResult;
  loadingPlaceholder?: () => ReactNode;
};

/**
 * Check ProviderType args against ContextTypeArgs
 * by checking if all args in requiredKeys exist.
 */
const enableArgs = <T extends SupportedContext, K extends ProviderShape<T>>({
  args,
  requiredKeys,
}: Pick<CSProviderProps<T, K>, "args" | "requiredKeys">): T | null => {
  if (requiredKeys && !requiredKeys?.every((key) => !isNullOrUndef(args[key]))) return null;

  return args as SupportedContext as T;
};

/**
 * Helper used to wrap and control our Providers.
 *
 * This is where we can make changes to take effect on all of our contexts.
 */
export const CSProvider = <T extends SupportedContext, K extends ProviderShape<T>>({
  children,
  Context,
  args,
  requiredKeys,
  feedback,
  loadingPlaceholder = () => <></>,
}: CSProviderProps<T, K>) => {
  // enabled if every required key/value pair
  const enabledArgs = enableArgs<T, K>({ args, requiredKeys });

  return (
    <>
      {!enabledArgs && <>{loadingPlaceholder()}</>}
      {enabledArgs && !feedback.hasError && <Context.Provider value={{ ...enabledArgs }}>{children}</Context.Provider>}
      <CSError {...feedback.errorProps} />
    </>
  );
};

/**
 * Used to instantiate a Context that can be passed on to a Provider and Context hook.
 *
 */
export const setupContext = <T extends SupportedContext>(name: string) => {
  const Context = createContext<T | undefined>(undefined);
  Context.displayName = name;
  return Context;
};

/**
 * Reusable context hook to fetch the given data from the provider.
 *
 * This method is meant to be used as a helper for creating custom context hooks.
 */
export const useCSContext = <T extends SupportedContext>({ Context, noProviderMsg }: UseContext<T>): T => {
  const contextShape = useContext(Context);
  if (contextShape === undefined) throw new Error(noProviderMsg || "Context cannot be used without Provider.");
  return contextShape;
};
