import "components/shared/button/CSRevealButton.scss";

import { Button, ButtonProps } from "react-bootstrap";
import React, { ReactNode } from "react";

import CSIconWithText from "components/shared/icon/CSIconWithText";
import classNames from "classnames";
import { isNullOrUndefOrEmpty } from "@civicscience/chops";

type CSRevealButtonProps = Omit<ButtonProps, "children" | "onClick"> & {
  buttonText: ReactNode;
  isSubmitting?: boolean;
  reveal?: boolean;
  itemToRevealText?: string;
  onToggleReveal?: ButtonProps["onClick"];
};

/**
 * Renders a button that indicates the user can "reveal" text.
 * This is typically used with a password input.
 * See https://react-bootstrap.github.io/components/buttons/#api for props.
 */
const CSRevealButton = ({
  type = "button",
  className,
  variant = "outline-medium",
  buttonText,
  reveal = false,
  itemToRevealText = "Password",
  onToggleReveal,
  ...buttonProps
}: CSRevealButtonProps) => {
  return (
    <Button
      {...buttonProps}
      type={type}
      variant={variant}
      className={classNames("csa-reveal-btn", className)}
      title={reveal ? `Hide ${itemToRevealText}` : `Show ${itemToRevealText}`}
      onClick={onToggleReveal}
      aria-hidden="true"
    >
      <CSIconWithText
        text={buttonText}
        iconLeftClassName={reveal ? "far fa-eye-slash" : "far fa-eye"}
        textClassName="csa-reveal-btn-text"
        isTextVisible={!isNullOrUndefOrEmpty(buttonText)}
      />
    </Button>
  );
};

export default CSRevealButton;
