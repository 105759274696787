import { ResponsiveBreakPoints } from "consts";

/**
 * Measures screen innerWidth to decide if screen is within mobile widths (including tablet)
 */
const isMobileScreenWidth = (): boolean => {
  return window.innerWidth < ResponsiveBreakPoints.Mobile[1];
};

export default isMobileScreenWidth;
