import { Button, ButtonProps } from "react-bootstrap";
import React, { ReactNode } from "react";

import CSIconWithText from "components/shared/icon/CSIconWithText";
import classNames from "classnames";

type CSIconButtonProps = Omit<ButtonProps, "title"> & {
  /**
   * Required to aid in a11y.
   */
  title: string;
  /**
   * The font-awesome CSS class/classes that identify the icon.
   */
  icon: string;
  /**
   * Any additional CSS you wish to apply to the icon.
   */
  iconClassName?: string;
  /**
   * Allows you to place icon before or after text
   */
  iconPlacement?: "start" | "end";
  /**
   * Any additional icon text to display after the icon.
   */
  iconText?: ReactNode;
  /**
   * Hide or show text
   */
  isTextVisible?: boolean;
};

/**
 * Renders a button that contains a font-awesome icon.
 */
const CSIconButton = ({
  title,
  icon,
  iconText,
  className,
  iconClassName,
  iconPlacement = "start",
  isTextVisible = false,
  children,
  ...buttonProps
}: CSIconButtonProps) => {
  const iconClassNames = classNames(icon, iconClassName);
  const iconLeftClassName = iconPlacement === "start" ? iconClassNames : undefined;
  const iconRightClassName = iconPlacement === "end" ? iconClassNames : undefined;
  return (
    <Button
      {...buttonProps}
      variant={buttonProps["variant"] || "link"}
      aria-label={buttonProps["aria-label"] || title}
      title={title}
      className={classNames("csa-icon-btn", className)}
    >
      <CSIconWithText
        text={iconText || title}
        isTextVisible={isTextVisible}
        iconLeftClassName={iconLeftClassName}
        iconRightClassName={iconRightClassName}
      />
      {children}
    </Button>
  );
};

export default CSIconButton;
