import { isDateValid, isNullOrUndef, isNullOrUndefOrEmpty } from "@civicscience/chops";

import qs from "qs";

export type QueryRecord = Record<string, unknown>;

/**
 * Represents the raw value stored in a query string key.
 */
type QueryValue = string | null | undefined;

/**
 * Combines a parser and a defaultValue to parse a raw individual value from the query string.
 */
export type ValueParser<T, TParseResult = T> = {
  defaultValue: T;
  parser: (val: QueryValue) => TParseResult;
};

/**
 * Short hand cast a Value Parser to a given Type.
 */
export type ValueParserTypeCast<T> = ValueParser<T[], T[]>;

/**
 * A record used to define all expected keys (and how to parse them) from a query string.
 *
 * @example
 *
 * const queryParser: QueryParser = {
 *   name: stringParserWithDefault(""),
 *   tags: commaArrayParserWithDefault([]),
 * };
 */
export type QueryParser = Record<
  string,
  ValueParser<string | number | null | boolean | Date | string[] | Record<string, unknown>>
>;

/**
 * Parses a query param to a Boolean.
 * This parser treats "nullish" values a `true`.
 * You can always provide a custom parser within your `QueryParser` if this does not work for your use case.
 */
const parseBool = (val: QueryValue): boolean => {
  // a bool param with no value is considered true
  // for example: '?isActive'
  if (isNullOrUndefOrEmpty(val)) {
    return true;
  }

  // otherwise, we expect it to be a "true" string
  return /^1$|^t$|^true$/i.test(val);
};

/**
 * Builds a Boolean parser with a default value.
 */
const boolParserWithDefault = (defaultValue: boolean): ValueParser<boolean> => ({ defaultValue, parser: parseBool });

/**
 * Parses a query param to a Number.
 * If the value parses to `NaN` then the default is returned.
 */
const parseNumber = (val: QueryValue): number | null => {
  const result = Number(val);
  return isNaN(result) ? null : result;
};

/**
 * Builds a Number parser with a default value.
 * @param {Number} defaultValue The default value
 * @returns {Object} parser object which includes a default.
 */
const numberParserWithDefault = (defaultValue: number): ValueParser<number, number | null> => ({
  defaultValue,
  parser: parseNumber,
});

/**
 * Parses a query param to a String.
 */
const parseString = (val: QueryValue): string | null => val ?? null;

/**
 * Builds a string parser with a default value.
 */
const stringParserWithDefault = (defaultValue: string): ValueParser<string, string | null> => ({
  defaultValue,
  parser: parseString,
});

/**
 * Parses a query param (serialized as a comma list) to an array of strings.
 */
const parseCommaArray = (val: QueryValue) => (val ? val.split(",") : []);

/**
 * Builds a string parser with a default value.
 */
const commaArrayParserWithDefault = (defaultValue: string[]): ValueParser<string[]> => ({
  defaultValue,
  parser: parseCommaArray,
});

/**
 * Parses a query param to a date.
 * TODO: The logic here should be unified with dateUtils.parseDateLike()
 *
 * https://stackoverflow.com/a/1353711
 */
const parseDate = (val: QueryValue): Date | null => {
  if (!val) {
    return null;
  }

  const dateInput = parseNumber(val) ?? val;
  const maybeDate = new Date(dateInput);
  return isDateValid(maybeDate) ? maybeDate : null;
};

/**
 * Builds a date parser with a default value.
 */
const dateParserWithDefault = (defaultValue: Date | null): ValueParser<Date | null> => ({
  defaultValue,
  parser: parseDate,
});

/**
 * Parses a query string using the provided queryParser object.
 * Each key in the queryParser object will be used to look for a key in the query string.
 * If the key is NOT found, the default value will be returned for that key.
 * If the key is found, then the result of your parser function will be returned.
 *
 * @example
 * getUrlParams({
 *   // you can provide a "custom" parser definition like this
 *   userId: { defaultValue: null, parser: val => Number(val) },
 *
 *   // or use one of the pre-built ones provided
 *   isActive: boolParserWithDefault(true)
 * })
 *
 * @see `useQueryString` for easier use in a react component.
 *
 * @param queryParser a Record whose keys map to query param names.
 * @param queryString a query string - commonly `location.search`.
 * @returns a Record of key/values with the result of parsing the query string.
 */
const getUrlParams = <T extends QueryParser, TResult = { [key in keyof T]: T[key]["defaultValue"] }>(
  queryParser: T,
  queryString: string,
): TResult => {
  const urlParams = new URLSearchParams(queryString);
  return Object.entries(queryParser).reduce((result, [queryKey, { defaultValue, parser }]) => {
    return {
      ...result,
      [queryKey]: !urlParams.has(queryKey) ? defaultValue : parser(urlParams.get(queryKey)) ?? defaultValue,
    };
  }, {}) as TResult;
};

/**
 * A default transformer that removes "unset" keys as well as
 * transforms Date instances to a timestring for easier parsing.
 */
const defaultParamTransformer = (params: QueryRecord): Record<string, string> => {
  return Object.entries(params).reduce((result, [key, val]) => {
    // remove "unset" variables
    if (isNullOrUndef(val)) {
      return result;
    }

    // We have special handling for dates and objects.
    // Dates are converted to a timestring (a number)
    // and objects (key value pairs) are serialized via `qs` lib.
    const convertedVal =
      val instanceof Date ? val.getTime() : typeof val === "object" && !Array.isArray(val) ? qs.stringify(val) : val;

    return {
      ...result,
      [key]: convertedVal,
    };
  }, {});
};

/**
 * Builds a query string from the provided key/value pair using `URLSearchParams`.
 * The `queryParams` are first passed through `paramTransformer` to provide
 * the ability to remove empty keys, transform dates, etc.
 * You can provide your own but the default transformer implements our common uses cases.
 *
 * NOTE: The returned string will NOT include a leading `?`
 * @see buildSearchString below if that is needed.
 *
 * @param queryParams a Record representing the query params.
 * @param paramTransformer a function to transform params before serializing to the query string.
 */
const buildQueryParams = (queryParams: QueryRecord, paramTransformer = defaultParamTransformer): string =>
  new URLSearchParams(paramTransformer(queryParams)).toString();

/**
 * Creates a search string, `?segment=1&target=2`, _including_ a leading `?` when there
 * are query values.
 *
 * @see buildQueryParams if you do NOT want a leading `?` as part of the returned string.
 *
 * @param queryParams a Record representing the query params.
 * @param paramTransformer a function to transform params before serializing to the query string.
 */
const buildSearchString = (queryParams: QueryRecord, paramTransformer = defaultParamTransformer): string => {
  const queryString = new URLSearchParams(paramTransformer(queryParams)).toString();
  return queryString ? `?${queryString}` : "";
};

export default {
  getUrlParams,
  parseBool,
  boolParserWithDefault,
  parseNumber,
  numberParserWithDefault,
  parseString,
  stringParserWithDefault,
  parseCommaArray,
  commaArrayParserWithDefault,
  parseDate,
  dateParserWithDefault,
  defaultParamTransformer,
  buildQueryParams,
  buildSearchString,
};
