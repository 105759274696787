import { MultiTimeview, MultiTimeviewForm, MultiTimeviewSimple, MultiTimeviewTimeviewResults } from "types";
import MultiTimeviewsAPI, { GetMultiTimeviewResultsArgs } from "api/services/MultiTimeviewsAPI";
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery, useQueryClient } from "react-query";

import { cacheKeyForDateRange } from "utils";
import { defaultChartQueryOptions } from "utils";

const queryKeys = {
  all: () => ["multi-timeviews"] as const,
  lists: () => [...queryKeys.all(), "list"] as const,
  listAll: () => [...queryKeys.lists(), "all"] as const,
  detail: (id: string) => [...queryKeys.all(), id] as const,
  results: (uuid: string) => [...queryKeys.all(), "result", uuid],
  resultsWithFilters: ({ uuid, startDate = null, endDate = null }: GetMultiTimeviewResultsArgs) => [
    ...queryKeys.results(uuid),
    { ...cacheKeyForDateRange({ startDate, endDate }) },
  ],
};

/**
 * Fetches a MultiTimeview by ID.
 */
export const useMultiTimeview = <T = MultiTimeview>(
  uuid: string,
  options: UseQueryOptions<MultiTimeview, unknown, T> = {},
) => useQuery<MultiTimeview, unknown, T>(queryKeys.detail(uuid), () => MultiTimeviewsAPI.get(uuid), options);

/**
 * Fetches a list of MultiTimeviewSimple instances - typically used within a select search.
 */
export const useAllMultiTimeviews = <T = MultiTimeviewSimple[]>(
  options: UseQueryOptions<MultiTimeviewSimple[], unknown, T> = {},
) => useQuery<MultiTimeviewSimple[], unknown, T>(queryKeys.listAll(), MultiTimeviewsAPI.getAll, options);

/**
 * Fetches the results for a MultiTimeview given an ID and filter options.
 */
export const useMultiTimeviewTimeviewResults = <T = MultiTimeviewTimeviewResults>(
  { uuid, endDate = null, startDate = null }: GetMultiTimeviewResultsArgs,
  options: UseQueryOptions<MultiTimeviewTimeviewResults, unknown, T> = {},
) => {
  return useQuery<MultiTimeviewTimeviewResults, unknown, T>(
    queryKeys.resultsWithFilters({ uuid, startDate, endDate }),
    () => MultiTimeviewsAPI.timeviewResults({ uuid, startDate, endDate }),
    { ...defaultChartQueryOptions, ...options },
  );
};

/**
 * Mutation to create a multi timeview.
 */
export const useCreateMultiTimeview = (options: UseMutationOptions<MultiTimeview, unknown, MultiTimeviewForm> = {}) =>
  useMutation((form) => MultiTimeviewsAPI.create(form), options);

/**
 * Mutation to update a multi timeview by id.
 *
 * NOTE: This _automatically_ invalidates the cache on success.
 */
export const useUpdateMultiTimeview = (
  uuid: string,
  options: UseMutationOptions<MultiTimeview, unknown, MultiTimeviewForm> = {},
) => {
  const queryClient = useQueryClient();
  return useMutation((form) => MultiTimeviewsAPI.update(uuid, form), {
    ...options,
    onSuccess: (response, variables, context) => {
      /**
       * IMPORTANT!
       *
       * The "results" **endpoint** of a multitimeview only accepts a few params (UUID and dates).
       * However, the result **data** is dependent on basically everything in this update form (stored in the db).
       * Therefore, every time we update the multitimeview we clear out the react-query cache for the results data.
       * We want to ensure that the data is requested fresh as changes to this form data can change the results.
       */
      queryClient.removeQueries(queryKeys.results(uuid));

      queryClient.setQueryData(queryKeys.detail(uuid), response);
      options.onSuccess?.(response, variables, context);
    },
  });
};

/**
 * Mutation to delete a multi timeview.
 */
export const useDeleteMultiTimeview = (options: UseMutationOptions<void, unknown, string> = {}) =>
  useMutation((uuid) => MultiTimeviewsAPI.delete(uuid), options);
