import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { User } from "types";
import envConfig from "config/envConfig";
import { isNodeEnvTest } from "utils/environment";
import { noOp } from "@civicscience/chops";

/**
 * When NODE_ENV is set to test this returns a noop function.
 * Otherwise, it returns the provided `fn` function unchanged.
 * This allows us to keep bugsnag calls from running during unit tests.
 */
const noopInTestEnv = <T>(fn: T) => (isNodeEnvTest() ? noOp : fn);

/**
 * Initializes the Bugsnag client.
 * Expected to be called during app startup.
 *
 * https://docs.bugsnag.com/platforms/javascript/react/#basic-configuration
 *
 * This seems like an interesting wrapper for later consideration:
 * https://www.npmjs.com/package/@shopify/react-bugsnag
 */
const initializeBugsnag = () =>
  Bugsnag.start({
    apiKey: envConfig.bugsnagApiKey || "",
    plugins: [new BugsnagPluginReact()],
    appVersion: envConfig.appVersion,
    releaseStage: envConfig.bugsnagReleaseStage,
    enabledReleaseStages: ["production", "qa"],
  });
/**
 * Set the Bugsnag user id for the current Bugsnag session.
 */
const setBugsnagUser = (userResponse: User) => {
  Bugsnag.setUser(userResponse.userId.toString());
  addBugsnagMetaData("user", {
    accountId: userResponse.accountRefid.toString(),
    isImpersonated: userResponse.isImpersonated.toString(),
  });
};

/**
 * Clear the Bugsnag user id for the current session.
 */
const clearBugsnagUser = () => Bugsnag.setUser(undefined);

/**
 * Add custom metadata to global bugsnag reporting
 * @param name Metadata Name
 * @param parameters Metadata Parameters to track
 */
const addBugsnagMetaData = (name: string, parameters: Record<string, string>) => {
  Bugsnag.addMetadata(name, parameters);
};

/**
 * Clear custom metadata from global bugsnag reporting
 * @param name Metadata Name
 */
const removeBugsnagMetaData = (name: string) => {
  Bugsnag.clearMetadata(name);
};

export default {
  initialize: noopInTestEnv(initializeBugsnag),
  setUser: noopInTestEnv(setBugsnagUser),
  clearUser: noopInTestEnv(clearBugsnagUser),
  addMetaData: noopInTestEnv(addBugsnagMetaData),
  removeMetaData: noopInTestEnv(removeBugsnagMetaData),
};
