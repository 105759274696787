import "components/shared/button/CSSubNavToggleButton.scss";

import CSSecondaryButton, { CSSecondaryButtonProps } from "components/shared/button/CSSecondaryButton";

import React, { ReactNode } from "react";
import classNames from "classnames";

type CSSubNavToggleButtonProps = Omit<CSSecondaryButtonProps, "children" | "buttonText"> & {
  buttonText?: ReactNode;
};

/**
 * Renders a button that is used for toggling the sub nav for mobile.
 * See https://react-bootstrap.github.io/components/buttons/#api for props.
 */
const CSSubNavToggleButton = ({
  className,
  buttonText = "Toggle Navigation",
  isSubmitting = false,
  variant = "outline-dark",
  title = "Toggle Mobile Navigation",
  iconLeftClass = "fas fa-chevron-circle-down",
  ...buttonProps
}: CSSubNavToggleButtonProps) => {
  return (
    <CSSecondaryButton
      {...buttonProps}
      variant={variant}
      className={classNames("csa-sub-nav-toggle w-100", className)}
      title={title}
      aria-label={title}
      disabled={isSubmitting}
      iconLeftClass={iconLeftClass}
      buttonText={buttonText}
    />
  );
};

export default CSSubNavToggleButton;
