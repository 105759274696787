import "fonts.js";
import "index.scss";

import App from "App";
import { BugsnagUtils } from "utils";
import { PostHogProvider } from "posthog-js/react";
import React from "react";
import ReactDOM from "react-dom";
import { envConfig } from "config";
import posthog from "posthog-js";

posthog.init(envConfig.posthogKey, {
  api_host: envConfig.posthogHost,
  person_profiles: "identified_only",
  capture_pageview: false,
});

BugsnagUtils.initialize();

const AppToRender = (
  <React.StrictMode>
    <PostHogProvider client={posthog}>
      <App />
    </PostHogProvider>
  </React.StrictMode>
);

ReactDOM.render(AppToRender, document.getElementById("root"));
