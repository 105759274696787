import { Link, LinkProps } from "react-router-dom";
import React, { ReactNode } from "react";

import CSIconWithText from "components/shared/icon/CSIconWithText";
import classNames from "classnames";

export type CSIconLinkProps = Omit<LinkProps, "title"> & {
  /**
   * Required for link path.
   */
  to: string;
  /**
   * Required to aid in a11y.
   */
  title?: string;
  /**
   * Required to aid in testing.
   */
  dataTestId?: string;
  /**
   * The font-awesome CSS class/classes that identify the icon.
   */
  icon?: string;
  /**
   * Any additional CSS you wish to apply to the icon.
   */
  iconClassName?: string;
  /**
   * Allows you to place icon before or after text
   */
  iconPlacement?: "start" | "end";
  /**
   * Any additional icon text to display after the icon.
   */
  iconText?: ReactNode;
  /**
   * Hide or show text
   */
  isTextVisible?: boolean;
  /**
   * Any additional CSS you wish to apply to the button text only.
   */
  textClassName?: string;
};

/**
 * Renders a link that contains a font-awesome icon.
 */
const CSIconLink = ({
  to,
  title,
  icon,
  iconText,
  className,
  iconClassName,
  iconPlacement = "start",
  isTextVisible = false,
  textClassName,
  dataTestId,
  children,
  ...linkProps
}: CSIconLinkProps) => {
  const iconClassNames = classNames(icon, iconClassName);
  const iconLeftClassName = iconPlacement === "start" ? iconClassNames : undefined;
  const iconRightClassName = iconPlacement === "end" ? iconClassNames : undefined;
  return (
    <Link
      {...linkProps}
      aria-label={linkProps["aria-label"] || title}
      title={title}
      to={to}
      className={classNames("csa-icon-link", className)}
      data-testid={dataTestId}
    >
      <CSIconWithText
        text={iconText || title || ""}
        isTextVisible={isTextVisible}
        textClassName={textClassName}
        iconLeftClassName={iconLeftClassName}
        iconRightClassName={iconRightClassName}
      />
      {children}
    </Link>
  );
};

export default CSIconLink;
