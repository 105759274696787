import { CSApiError, isNullOrUndefOrEmpty } from "@civicscience/chops";
import React, { createContext, useContext } from "react";
import { UseQueryResult, useQuery, useQueryClient } from "react-query";

import { MultiScore } from "types";
import { MultiScoresAPI } from "api";

type MultiScoreProviderProps = {
  id: string | number;
  children: React.ReactNode;
};

type MultiScoreError = string | CSApiError | null | undefined;

const emptySetMultiScore = (multiScore: MultiScore) => multiScore;

type MultiScoreContextProps = {
  currentMultiScoreID: string | number;
  currentMultiScore?: MultiScore | null;
  setCurrentMultiScore: (multiScore: MultiScore) => MultiScore;
  multiScoreIsLoaded?: boolean;
  multiScoreIsFetching?: boolean;
  multiScoreError?: MultiScoreError;
};

const emptyContext = {
  currentMultiScoreID: "",
  currentMultiScore: null,
  multiScoreIsLoaded: false,
  multiScoreIsFetching: false,
  multiScoreError: null,
  setCurrentMultiScore: emptySetMultiScore,
};

export const MultiScoreContext = createContext<MultiScoreContextProps>(emptyContext);
MultiScoreContext.displayName = "MultiScoreContext";

/**
 * MultiScoreProvider provides the currently selected Multi Score
 */
const MultiScoreProvider = ({ id, children }: MultiScoreProviderProps) => {
  const queryClient = useQueryClient();
  const QUERY_KEY = `multi-score-id-${id}`;

  const {
    data: currentMultiScore,
    isFetched: multiScoreIsLoaded,
    isFetching: multiScoreIsFetching,
    error: multiScoreError,
  }: UseQueryResult<MultiScore, MultiScoreError> = useQuery(QUERY_KEY, () => MultiScoresAPI.get(id), {
    enabled: !isNullOrUndefOrEmpty(id),
  });

  const setCurrentMultiScore = (newMultiScoreResponse: MultiScore) =>
    queryClient.setQueryData(QUERY_KEY, newMultiScoreResponse);

  return (
    <MultiScoreContext.Provider
      value={{
        currentMultiScoreID: id,
        currentMultiScore,
        setCurrentMultiScore,
        multiScoreIsLoaded,
        multiScoreIsFetching,
        multiScoreError,
      }}
    >
      {children}
    </MultiScoreContext.Provider>
  );
};

/**
 * Functionally provides the currently selected Multi Score
 */
export const useMultiScoreState = () => {
  const {
    currentMultiScoreID,
    currentMultiScore,
    setCurrentMultiScore,
    multiScoreIsLoaded,
    multiScoreIsFetching,
    multiScoreError,
  } = useContext(MultiScoreContext);
  return {
    currentMultiScoreID,
    currentMultiScore,
    setCurrentMultiScore,
    multiScoreIsLoaded,
    multiScoreIsFetching,
    multiScoreError,
  };
};

export default MultiScoreProvider;
