import { scrollToNode } from "@civicscience/chops";
import { useEffect } from "react";

/**
 *
 * @param scrollNodeRef {function|React.Ref} - Ref created by React.useRef that has tabIndex
 */
const handleScrollToNode = (scrollNodeRef) => {
  if (scrollNodeRef && scrollNodeRef.current) {
    scrollNodeRef.current?.focus();
    //NOTE: scrollToNode() should be called after focus to prevent the focus from breaking the scroll
    scrollToNode(scrollNodeRef.current, { behavior: "smooth" });
  } else {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }
};

//Note: Scroll on route pulled directly @ https://reactrouter.com/web/guides/scroll-restoration
//This triggers on ALL route changes.
/**
 *
 * @param {object} options
 * @param {string|optional} [options.pathname]  - the current URL pathname so it can be watched for changes.
 * @param {function|React.Ref} options.scrollNodeRef  - Ref created by React.useRef that has tabIndex
 * @param {boolean} [options.isMountOnly]  - Scrolling will happen only on first mount
 * @param {boolean} [options.canScroll]  - Scrolling will happen only when true
 */
export const useRouterScrollTop = ({ pathname = "", scrollNodeRef, isMountOnly = false, canScroll = true }) => {
  useEffect(() => {
    !isMountOnly && canScroll && handleScrollToNode(scrollNodeRef);
  }, [pathname, scrollNodeRef, isMountOnly, canScroll]);

  useEffect(() => {
    isMountOnly && canScroll && handleScrollToNode(scrollNodeRef);
  }, [scrollNodeRef, isMountOnly, canScroll]);
};

export default useRouterScrollTop;
