import "components/shared/button/CSLinkButton.scss";

import CSSecondaryButton, { CSSecondaryButtonProps } from "components/shared/button/CSSecondaryButton";

import { LocationDescriptor } from "history";
import React from "react";
import classNames from "classnames";

type CSLinkButtonProps = Omit<CSSecondaryButtonProps, "children"> & {
  isSubmitting?: boolean;
  to?: LocationDescriptor;
};

/**
 * Renders a button that is used for a Link button.
 * See https://react-bootstrap.github.io/components/buttons/#api for props.
 */
const CSLinkButton = ({ className, variant = "link", buttonText, to, ...buttonProps }: CSLinkButtonProps) => {
  return (
    <CSSecondaryButton
      {...buttonProps}
      variant={variant}
      className={classNames("cs-link-btn", className)}
      buttonText={buttonText}
      to={to}
    />
  );
};

export default CSLinkButton;
