import CSSubmitButton, { CSSubmitButtonProps } from "components/shared/button/CSSubmitButton";
import React from "react";

import classNames from "classnames";

type CSUpdateButtonProps = Omit<CSSubmitButtonProps, "children">;

/**
 * Renders a button that is used for a submit button within a form.
 * See https://react-bootstrap.github.io/components/buttons/#api for props.
 */
const CSUpdateButton = ({
  className,
  type = "submit",
  buttonText = "Update",
  variant = "primary",
  isSubmitting = false,
  ...buttonProps
}: CSUpdateButtonProps) => {
  return (
    <CSSubmitButton
      {...buttonProps}
      buttonText={buttonText}
      type={type}
      variant={variant}
      isSubmitting={isSubmitting}
      className={classNames("csa-update-btn", className)}
    />
  );
};

export default CSUpdateButton;
