/**
 * Define `Intercom` on the window object.
 */
declare global {
  interface Window {
    Intercom: (action: string, payload?: IntercomConfig | string, meta?: IntercomMetadata | null) => void;
  }
}

type IntercomConfig = Record<string, unknown>;
type IntercomMetadata = Record<string, unknown>;

export type BootIntercomArgs = {
  /**
   * Intercom App Id
   */
  appId: string;
  /**
   * Site user first and last name
   */
  userFullName: string;
  userId: number;
  email: string;
  isSysAdmin: boolean;
  accountId: number;
  isPublisherAccount: boolean;
};

/**
 * Send user specific initialization to Intercom support platform.
 * https://developers.intercom.com/installing-intercom/web/methods/#intercomboot-intercomsettings
 */
export const bootIntercomUser = ({
  appId,
  userFullName,
  userId,
  email,
  isSysAdmin,
  accountId,
  isPublisherAccount,
}: BootIntercomArgs) => {
  // TODO: catch up to IS1 here, which will require a refactor
  // as we need account info from AuthContext not just user

  // IS1 code below:
  // window.intercomSettings = {

  // 	// required user attributes
  // 	app_id: <?=json_encode(INTERCOM_APPLICATION_ID)?>,
  //     name: <?=json_encode($userName)?>,
  //     email: <?=json_encode($userEmail)?>,
  //     created_at: <?=max($user->getRegisteredTime(), 1)?>,
  //     // custom user attributes
  //     "user_id": <?=$user->getDatabaseId()?>,
  //     "sysadmin": <?=json_encode($user->isSysadmin())?>,
  //     // account-level attributes
  //     company: {
  //         // required
  //         id: <?=$account->getDatabaseId()?>,
  //         name: <?=json_encode($account->getName())?>,
  //         created_at: 0, // unknown
  //         // custom
  //         "publisher": <?=json_encode(!$account->isDataCustomer())?>
  //     }
  // };

  if (window.Intercom) {
    window.Intercom("boot", {
      app_id: appId,
      name: userFullName,
      user_id: userId,
      email,
      sysadmin: isSysAdmin,
      company: {
        id: accountId,
        publisher: isPublisherAccount,
      },
    });
  }
};

/**
 * Opens the Intercom Messenger.
 * https://developers.intercom.com/installing-intercom/web/methods/#intercomshownewmessage
 */
export const openIntercomMessenger = () => {
  if (window.Intercom) {
    window.Intercom("showNewMessage");
  }
};

/**
 * Clears the Intercom conversation - used when a user logs out.
 * https://developers.intercom.com/installing-intercom/web/methods/#intercomshutdown
 */
export const shutdownIntercom = () => {
  if (window.Intercom) {
    window.Intercom("shutdown");
  }
};

/**
 * Associate an event with the current intercom user.
 * https://developers.intercom.com/installing-intercom/web/methods/#intercomtrackevent
 */
export const trackIntercomEvent = (name: string, metadata: IntercomMetadata | null) => {
  if (window.Intercom) {
    window.Intercom("trackEvent", name, metadata);
  }
};
