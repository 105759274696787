import { ObjectValues } from "types";

const QuestionOwnershipTerms = {
  ALL: "type_all",
  SYNDICATED: "type_syndicated",
  CUSTOM: "type_custom",
} as const;

export type QuestionOwnershipTerm = ObjectValues<typeof QuestionOwnershipTerms>;

export default QuestionOwnershipTerms;
