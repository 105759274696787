type ArrayToLabelTextOptions = {
  delimiter?: string;
  /**
   * Used when the array contains exactly 2 entries and is placed between them.
   * Defaults to ' and '.
   */
  twoItemsSeparator?: string;
  /**
   * Used when the array contains more than 2 entries and is placed before the last item.
   * Defaults to ', and '.
   */
  threeOrMoreItemsSeparator?: string;
};

/**
 * Creates a delimited string from an array of items using `toString()` on each item.
 * This is most commonly used to get a comma list of items for display.
 *
 * @example
 *
 * arrayToLabelText([]) // => ""
 * arrayToLabelText(["filter1"]) // => "filter1"
 * arrayToLabelText(["filter1", "filter2"]) // => "filter1 and filter 2"
 * arrayToLabelText(["filter1", "filter2", "filter3"]) // => "filter1, filter 2, and filter 3"
 */
const arrayToDelimitedText = <T extends { toString: () => string }>(
  items: T[],
  { delimiter = ", ", twoItemsSeparator = " and ", threeOrMoreItemsSeparator = ", and " }: ArrayToLabelTextOptions = {},
): string => {
  if (items.length < 2) {
    return (items[0] ?? "").toString();
  }

  if (items.length === 2) {
    return items[0] + twoItemsSeparator + items[1];
  }

  const allButLastItem = items.slice(0, -1);
  const lastItem = items[items.length - 1];
  return allButLastItem.join(delimiter) + threeOrMoreItemsSeparator + lastItem.toString();
};

export default arrayToDelimitedText;
