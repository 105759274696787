import { FieldErrors, useForm } from "react-hook-form";
import { debug, usePageFeedback } from "utils";
import { useMutation, useQueryClient } from "react-query";

import CSButton from "components/shared/button/CSButton";
import Error from "components/shared/error/Error";
import { Form } from "react-bootstrap";
import { ProfileAPI } from "api";
import React from "react";
import { UserWithPermissions } from "types";
import buildUserDisplayName from "utils/buildUserDisplayName";
import { routePaths } from "routing";
import { useAuthState } from "context/AuthContext";
import { useHistory } from "react-router-dom";

const GlobalImpersonationForm = (): JSX.Element => {
  const queryClient = useQueryClient();
  const pageFeedback = usePageFeedback();

  const history = useHistory();
  const { currentUser, onSetCurrentUser } = useAuthState();
  const displayName = buildUserDisplayName(currentUser);

  const { formState, handleSubmit } = useForm({ mode: "onChange" });
  const { isSubmitting } = formState;

  const handleUserChange = (response: UserWithPermissions) => {
    onSetCurrentUser(response);
    // Clear ALL cached query data after switching users.
    // This ensures we don't show data from your previous user as all queries will re-fetch.
    queryClient.clear();
    history.push(`${routePaths.PROFILE}`);
  };

  const cancelImpersonation = useMutation(() => ProfileAPI.stopImpersonating(), {
    onSuccess: handleUserChange,
    onError: pageFeedback.handleErrorWithMessage,
  });

  const onCancelImpersonation = () => {
    pageFeedback.clearAll();
    cancelImpersonation.mutate();
  };

  const onFormError = (errors: FieldErrors) => {
    debug.error("onFormError", { errors });
    pageFeedback.clearAndSetError("Form Error");
  };

  const onFormSubmit = handleSubmit(onCancelImpersonation, onFormError);

  return (
    <Form className="csa-global-impersonation-form text-center py-1 px-2 bg-info-light" onSubmit={onFormSubmit}>
      <Error {...pageFeedback.errorProps} />

      <div className="d-inline-block align-middle m-0 small">You are impersonating {displayName}.</div>

      <CSButton.Submit
        className="h5 m-0"
        size="sm"
        variant="link"
        buttonText="Stop Impersonating"
        disabled={isSubmitting}
        isSubmitting={isSubmitting}
      />
    </Form>
  );
};

export default GlobalImpersonationForm;
