import CSSecondaryButton, { CSSecondaryButtonProps } from "components/shared/button/CSSecondaryButton";

import React from "react";
import classNames from "classnames";

export type CSDangerButtonProps = Omit<CSSecondaryButtonProps, "children">;

/**
 * Renders a button that is used for a Danger buttons.
 * See https://react-bootstrap.github.io/components/buttons/#api for props.
 */
const CSDangerButton = ({ className, ...buttonProps }: CSDangerButtonProps) => {
  return <CSSecondaryButton {...buttonProps} variant="danger" className={classNames("cs-danger-btn", className)} />;
};

export default CSDangerButton;
