import "components/shared/header/AppHeader.scss";

import AppHeaderNavbar from "components/shared/navigation/AppHeaderNavbar";
import React from "react";

const AppHeader = (): JSX.Element => {
  return (
    <header className="csa-app-header">
      <AppHeaderNavbar />
    </header>
  );
};

export default AppHeader;
