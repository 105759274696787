export type ErrorWithMessage = {
  message: string;
};

/**
 * Determine if this is an object with a message property.
 */
export const isErrorWithMessage = (err: unknown): err is ErrorWithMessage =>
  !!err && typeof err === "object" && "message" in err;

/**
 * Attempts to return the `message` property from the provided @param err.
 * Returns `err.message` if it exists and is truthy.
 * Returns @param defaultMessage if `err.message` does not exist or is falsy.
 * Returns `null` in all other cases.
 */
export const extractErrorMessageOrDefault = (
  err: unknown,
  defaultMessage: string | null = "There was an unknown error.",
): string | null => {
  if (!err) {
    return null;
  }

  return isErrorWithMessage(err) ? err.message || defaultMessage : defaultMessage;
};
