/**
 * Common "types" of sorts that are used across multiple search pages.
 * Useful to associate default values to each type of sort.
 */
export const SortTypes = {
  NAME: "name",
  NAME_DESC: "name-desc",
  NAME_ASC: "name-asc",
  NEWEST_CREATED: "created-desc",
  OLDEST_CREATED: "created-asc",
  NEWEST_UPDATED: "updated-desc",
  OLDEST_UPDATED: "updated-asc",
  RELEVANCE: "relevance",
  RESPONSES: "responses",
  RESPONSES_DESC: "responses-desc",
  RESPONSES_ASC: "responses-asc",
  STRENGTH_OF_ASSOCIATION: "strength-association",
} as const;

export type SortType = (typeof SortTypes)[keyof typeof SortTypes];

export default SortTypes;
