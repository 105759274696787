import React, { HTMLAttributes } from "react";
import classNames from "classnames";
import { isNullOrUndefOrEmpty } from "@civicscience/chops";

type CSIconProps = HTMLAttributes<HTMLSpanElement> & {
  /**
   * className for icon (example: 'fas fa-close')
   */
  icon: string;
};

//NOTE: .csa-i and .csa-i-text styling found in styles/partials/_icons.scss

/**
 * Renders an icon applying proper attributes including a11y.
 * Accepts all the same props as a span tag.
 */
const CSIcon = ({ icon, className, ...spanProps }: CSIconProps): JSX.Element | null => {
  if (isNullOrUndefOrEmpty(icon)) {
    return null;
  }

  return <i {...spanProps} className={classNames("csa-i", className, icon)} aria-hidden={true} />;
};

export default CSIcon;
