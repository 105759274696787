import routePaths, { RoutePathType } from "routing/routePaths";

import { UserPermissionType } from "consts/UserPermissionTypes";
import lazyWithRetry from "utils/lazyWithRetry";

//NOTE: The following list of lazy imports MUST be in alphabetical order.
const About = lazyWithRetry(() => import("components/about/About"));
const CheckboxGroupList = lazyWithRetry(() => import("components/checkbox-groups/CheckboxGroupList"));
const CheckboxGroup = lazyWithRetry(() => import("components/checkbox-groups/CheckboxGroup"));
const CheckboxGroupDetail = lazyWithRetry(() => import("components/checkbox-groups/CheckboxGroupDetail"));
const Contract = lazyWithRetry(() => import("components/auth/Contract"));
const CrosstabCreate = lazyWithRetry(() => import("components/reports/crosstabs/CrosstabCreate"));
const Crosstab = lazyWithRetry(() => import("components/reports/crosstabs/Crosstab"));
const DeepProfile = lazyWithRetry(() => import("components/reports/deep-profiles/DeepProfile"));
const DeepProfileCreate = lazyWithRetry(() => import("components/reports/deep-profiles/DeepProfileCreate"));
const DashboardDefault = lazyWithRetry(() => import("components/dashboard/DashboardDefault"));
const Dashboard = lazyWithRetry(() => import("components/dashboard/Dashboard"));
const DeploymentSchemeDetail = lazyWithRetry(() => import("components/deployment-schemes/DeploymentSchemeDetail"));
const DeploymentSchemeList = lazyWithRetry(() => import("components/deployment-schemes/DeploymentSchemeList"));
const ForgotPassword = lazyWithRetry(() => import("components/auth/ForgotPassword"));
const Home = lazyWithRetry(() => import("components/home/Home"));
const Login = lazyWithRetry(() => import("components/auth/Login"));
const Logout = lazyWithRetry(() => import("components/auth/Logout"));
const MatrixQuestionDetail = lazyWithRetry(() => import("components/matrix-questions/MatrixQuestionDetail"));
const MatrixQuestionList = lazyWithRetry(() => import("components/matrix-questions/MatrixQuestionList"));
const MultiScoreCreate = lazyWithRetry(() => import("components/reports/multi-scores/MultiScoreCreate"));
const MultiScore = lazyWithRetry(() => import("components/reports/multi-scores/MultiScore"));
const MultiTimeviewDetail = lazyWithRetry(() => import("components/reports/multi-timeviews/MultiTimeview"));
const MultiTimeviewCreate = lazyWithRetry(() => import("components/reports/multi-timeviews/MultiTimeviewCreate"));
const NPTBusinessList = lazyWithRetry(() => import("components/nugget-preset-traits/NuggetPresetTraitBusinessList"));
const Profile = lazyWithRetry(() => import("components/profile/Profile"));
const Question = lazyWithRetry(() => import("components/questions/Question"));
const QuestionList = lazyWithRetry(() => import("components/questions/QuestionList"));
const QuestionFeed = lazyWithRetry(() => import("components/question-feeds/QuestionFeed"));
const QuestionFeedList = lazyWithRetry(() => import("components/question-feeds/QuestionFeedList"));
const ReportList = lazyWithRetry(() => import("components/reports/ReportList"));
const RequestPasswordReset = lazyWithRetry(() => import("components/auth/RequestPasswordReset"));
const ResetPassword = lazyWithRetry(() => import("components/auth/ResetPassword"));
const ScoreCardDetail = lazyWithRetry(() => import("components/reports/score-cards/ScoreCard"));
const ScorecardCreate = lazyWithRetry(() => import("components/reports/ScorecardCreate"));
const ScoreTableDetail = lazyWithRetry(() => import("components/score-tables/ScoreTableDetail"));
const ScoreTableList = lazyWithRetry(() => import("components/score-tables/ScoreTableList"));
const Segment = lazyWithRetry(() => import("components/segments/Segment"));
const SegmentList = lazyWithRetry(() => import("components/segments/SegmentList"));
const StarRatingQuestionList = lazyWithRetry(() => import("components/star-rating-questions/StarRatingQuestionList"));
const StatsTestDetail = lazyWithRetry(() => import("components/score-tables/StatsTestDetail"));
const SysAdmin = lazyWithRetry(() => import("components/sysadmin/SysAdmin"));
const TagList = lazyWithRetry(() => import("components/tags/TagList"));
const TagDetail = lazyWithRetry(() => import("components/tags/TagDetail"));
const Target = lazyWithRetry(() => import("components/targets/Target"));
const TargetList = lazyWithRetry(() => import("components/targets/TargetList"));
const TermsOfUse = lazyWithRetry(() => import("components/auth/TermsOfUse"));
const Toolkit = lazyWithRetry(() => import("components/toolkit/Toolkit"));
const TrendingQuestionsList = lazyWithRetry(
  () => import("components/sysadmin/trending-questions/TrendingQuestionsList"),
);
const UserRegister = lazyWithRetry(() => import("components/auth/UserRegister"));
const UserConfirm = lazyWithRetry(() => import("components/auth/UserConfirm"));
const WeightingScheme = lazyWithRetry(() => import("components/weighting-schemes/WeightingScheme"));
const WeightingSchemeList = lazyWithRetry(() => import("components/weighting-schemes/WeightingSchemeList"));

type RouteType = {
  path: RoutePathType;
  component: React.LazyExoticComponent<React.ComponentType<unknown>>;
  isProtected: boolean;
  permission?: UserPermissionType;
};

// TODO: Add permissions to rest of paths
const routes: RouteType[] = [
  {
    path: routePaths.TOOLKIT,
    component: Toolkit,
    isProtected: true,
  },
  {
    path: routePaths.ABOUT,
    component: About,
    isProtected: true,
  },
  {
    path: routePaths.TARGET,
    component: Target,
    isProtected: true,
  },
  {
    path: routePaths.TARGETS,
    component: TargetList,
    isProtected: true,
  },
  {
    path: routePaths.TRENDING_QUESTIONS,
    component: TrendingQuestionsList,
    isProtected: true,
  },
  {
    path: routePaths.NUGGET_PRESET_TRAIT_BUSINESS,
    component: NPTBusinessList,
    isProtected: true,
  },
  {
    path: routePaths.SYSADMIN,
    component: SysAdmin,
    isProtected: true,
  },
  {
    path: routePaths.CHECKBOX_QUESTION,
    component: CheckboxGroup,
    isProtected: true,
  },
  {
    path: routePaths.CHECKBOX_GROUP_DETAIL,
    component: CheckboxGroupDetail,
    isProtected: true,
  },
  {
    path: routePaths.CHECKBOX_GROUP_CREATE,
    component: CheckboxGroupDetail,
    isProtected: true,
  },
  {
    path: routePaths.CHECKBOX_GROUPS,
    component: CheckboxGroupList,
    isProtected: true,
  },
  {
    path: routePaths.WEIGHTING_SCHEME_CREATE,
    component: WeightingScheme,
    isProtected: true,
  },
  {
    path: routePaths.WEIGHTING_SCHEME_DETAIL,
    component: WeightingScheme,
    isProtected: true,
  },
  {
    path: routePaths.WEIGHTING_SCHEMES,
    component: WeightingSchemeList,
    isProtected: true,
  },
  {
    path: routePaths.MATRIX_QUESTION_DETAIL,
    component: MatrixQuestionDetail,
    isProtected: true,
  },
  {
    path: routePaths.MATRIX_QUESTIONS,
    component: MatrixQuestionList,
    isProtected: true,
  },
  {
    path: routePaths.MATRIX_QUESTION_CREATE,
    component: MatrixQuestionDetail,
    isProtected: true,
  },
  {
    path: routePaths.MULTI_SCORE_CREATE,
    component: MultiScoreCreate,
    isProtected: true,
  },

  {
    path: routePaths.MULTI_SCORE,
    component: MultiScore,
    isProtected: true,
  },
  {
    path: routePaths.SEGMENT,
    component: Segment,
    isProtected: true,
  },
  {
    path: routePaths.SEGMENTS,
    component: SegmentList,
    isProtected: true,
  },
  {
    path: routePaths.DEPLOYMENT_SCHEME_CREATE,
    component: DeploymentSchemeDetail,
    isProtected: true,
  },
  {
    path: routePaths.DEPLOYMENT_SCHEME_DETAIL,
    component: DeploymentSchemeDetail,
    isProtected: true,
  },
  {
    path: routePaths.DEPLOYMENT_SCHEMES,
    component: DeploymentSchemeList,
    isProtected: true,
  },
  {
    path: routePaths.DEEP_PROFILE_CREATE,
    component: DeepProfileCreate,
    isProtected: true,
  },
  {
    path: routePaths.DEEP_PROFILE,
    component: DeepProfile,
    isProtected: true,
  },
  {
    path: routePaths.SCORECARD_CREATE,
    component: ScorecardCreate,
    isProtected: true,
  },
  {
    path: routePaths.MULTI_TIMEVIEW_CREATE,
    component: MultiTimeviewCreate,
    isProtected: true,
  },
  {
    path: routePaths.MULTI_TIMEVIEW,
    component: MultiTimeviewDetail,
    isProtected: true,
  },
  {
    path: routePaths.SCORECARD_DETAIL,
    component: ScoreCardDetail,
    isProtected: true,
  },
  {
    path: routePaths.CROSSTAB_CREATE,
    component: CrosstabCreate,
    isProtected: true,
  },
  {
    path: routePaths.CROSSTAB,
    component: Crosstab,
    isProtected: true,
  },
  {
    path: routePaths.REPORTS,
    component: ReportList,
    isProtected: true,
  },
  {
    path: routePaths.DASHBOARD,
    component: Dashboard,
    isProtected: true,
  },
  {
    path: routePaths.DASHBOARD_DEFAULT,
    component: DashboardDefault,
    isProtected: true,
  },
  {
    path: routePaths.HOME,
    component: Home,
    isProtected: true,
  },
  {
    path: routePaths.LOGIN,
    component: Login,
    isProtected: false,
  },
  {
    path: routePaths.REQUEST_PASSWORD_RESET,
    component: RequestPasswordReset,
    isProtected: false,
  },
  {
    path: routePaths.RESET_PASSWORD,
    component: ResetPassword,
    isProtected: false,
  },
  {
    path: routePaths.FORGOT_PASSWORD,
    component: ForgotPassword,
    isProtected: false,
  },
  {
    path: routePaths.TERMS_OF_USE,
    component: TermsOfUse,
    isProtected: true,
  },
  {
    path: routePaths.CONTRACT,
    component: Contract,
    isProtected: true,
  },
  {
    path: routePaths.QUESTION,
    component: Question,
    isProtected: true,
  },
  {
    path: routePaths.QUESTIONS,
    component: QuestionList,
    isProtected: true,
  },
  {
    path: routePaths.QUESTION_FEED_CREATE,
    component: QuestionFeed,
    isProtected: true,
    permission: "question-feed:create",
  },
  {
    path: routePaths.QUESTION_FEED_DETAIL,
    component: QuestionFeed,
    isProtected: true,
    permission: "question-feed:list",
  },
  {
    path: routePaths.QUESTION_FEEDS,
    component: QuestionFeedList,
    isProtected: true,
    permission: "question-feed:list",
  },
  {
    path: routePaths.PROFILE,
    component: Profile,
    isProtected: true,
  },
  {
    path: routePaths.STATS_TEST,
    component: StatsTestDetail,
    isProtected: true,
  },
  {
    path: routePaths.SCORE_TABLE_DETAIL,
    component: ScoreTableDetail,
    isProtected: true,
  },
  {
    path: routePaths.SCORE_TABLES,
    component: ScoreTableList,
    isProtected: true,
  },
  {
    path: routePaths.USER_REGISTRATION_CONFIRM,
    component: UserConfirm,
    isProtected: false,
  },
  {
    path: routePaths.USER_REGISTRATION,
    component: UserRegister,
    isProtected: false,
  },
  {
    path: routePaths.STAR_RATING_QUESTIONS,
    component: StarRatingQuestionList,
    isProtected: true,
  },
  {
    path: routePaths.TAG_DETAIL,
    component: TagDetail,
    isProtected: true,
  },
  {
    path: routePaths.TAGS,
    component: TagList,
    isProtected: true,
  },
  {
    path: routePaths.LOGOUT,
    component: Logout,
    isProtected: true,
  },
];

export const defaultPath = routePaths.HOME;

export const defaultRoute = { path: defaultPath, text: "Home" };

export const unprotectedRoutes = routes.filter((route) => !route.isProtected);

export const protectedRoutes = routes.filter((route) => route.isProtected);

/**
 * If a permission is supplied to the route, check that it exists within userPermissions.
 */
export const permissionProtectedRoutes = (userPermissions: UserPermissionType[]) =>
  protectedRoutes.filter((route) => (route?.permission ? userPermissions.includes(route.permission) : true));

export default routes;
