import React, { HTMLAttributes } from "react";

type LinkToNewTabProps = HTMLAttributes<HTMLAnchorElement> & {
  href: string;
  target?: string;
};
/**
 * Renders an anchor that opens in a new tab.
 */
const LinkToNewTab = ({
  href,
  children,
  className,
  target = "_blank",
  ...anchorProps
}: LinkToNewTabProps): JSX.Element => (
  <a href={href} rel="noopener noreferrer" target={target} className={className} {...anchorProps}>
    {children}
  </a>
);

export default LinkToNewTab;
