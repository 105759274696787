import { routePaths, unprotectedRoutes } from "routing";

import { getPathNamesFromRoutes } from "routing";
import { isNullOrUndefOrEmpty } from "@civicscience/chops";
import { protectedRoutes } from "routing/routes";

//Root Urls with variations of end slash and including publicUrl
const rootPaths = ["", "/"];
const loginPath = `${routePaths.LOGIN}`;
const logOutErrorParam = "loggedOutError";
const fromUrlParam = "fromUrl";

const getUnauthorizedPathNames = (): string[] => getPathNamesFromRoutes(unprotectedRoutes ?? []);
const getAuthorizedPathNames = (): string[] => getPathNamesFromRoutes(protectedRoutes);

/**
 *
 * @param fromUrl - href to create fromUrl base64 param
 */
const getLoggedOutUrl = (fromUrl?: string): string => {
  const fromUrlToTest = fromUrl || window.location.href;
  const fromUrlToBase64 = isNullOrUndefOrEmpty(fromUrlToTest) ? null : window.btoa(fromUrlToTest);
  const fromUrlParams = fromUrlToBase64 ? `&${fromUrlParam}=${fromUrlToBase64}` : "";
  return `${loginPath}?${logOutErrorParam}=true${fromUrlParams}`;
};

const hasLoggedOutError = (url?: string): boolean => {
  const urlToTest = url || window.location.href;
  const { searchParams } = new URL(urlToTest);
  return searchParams.get(logOutErrorParam) === "true";
};

/**
 *
 * @return (string|null) - fromUrl Url.searchParam converted from base64 string OR null
 */
const getFromUrlParam = (url?: string): string | null => {
  const urlToTest = url || window.location.href;
  const { searchParams } = new URL(urlToTest);
  const hasFromUrl = searchParams.has(fromUrlParam);
  const fromUrlParamForBase64 = hasFromUrl ? searchParams.get(fromUrlParam) : null;
  return hasLoggedOutError(url) && fromUrlParamForBase64 ? window.atob(fromUrlParamForBase64) : null;
};

const getLoggedOutError = (url?: string) => {
  const fromUrl = getFromUrlParam(url || window.location.href);
  const showFromUrl = true; //NOTE: If we want to show the url from where the logout took place.
  // We can turn this off based on environment if we want to.
  const fromUrlMessaging = showFromUrl && fromUrl ? " accessing @ " + fromUrl : "";
  return `Something went wrong${fromUrlMessaging}. Please login and try again.`;
};

//NOTE: Checking IF we are already @ an authenticated route OR IF user was going to the publicUrl
const canRedirectToLoginFrom401 = (urlPathName?: string) => {
  const unauthorizedPathNames = getUnauthorizedPathNames();
  const currentPathName = urlPathName || window.location.pathname;
  const isNotRootPath = !rootPaths.includes(currentPathName);
  return !unauthorizedPathNames.some((x) => currentPathName.startsWith(x)) && isNotRootPath;
};

export default {
  loginPath,
  logOutErrorParam,
  fromUrlParam,
  hasLoggedOutError,
  getLoggedOutError,
  getLoggedOutUrl,
  getFromUrlParam,
  getPathNamesFromRoutes,
  getUnauthorizedPathNames,
  getAuthorizedPathNames,
  canRedirectToLoginFrom401,
};
