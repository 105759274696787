import { ResponsiveBreakPoints } from "consts";

/**
 * Measures screen innerWidth to decide if screen is above notebook screen min width and below desktop screen width
 */
const isNotebookScreenWidth = (): boolean => {
  return window.innerWidth > ResponsiveBreakPoints.Notebook[0] && window.innerWidth < ResponsiveBreakPoints.Notebook[1];
};

export default isNotebookScreenWidth;
