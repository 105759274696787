import CSSecondaryButton, { CSSecondaryButtonProps } from "components/shared/button/CSSecondaryButton";
import React, { ReactNode } from "react";

import classNames from "classnames";

type CSSidebarToggleButtonProps = Omit<CSSecondaryButtonProps, "children" | "buttonText"> & {
  buttonText?: ReactNode;
};

/**
 * Renders a button that is used for toggling the sub nav for mobile.
 * See https://react-bootstrap.github.io/components/buttons/#api for props.
 */
const CSSidebarToggleButton = ({
  className,
  buttonText = "Options",
  variant = "outline-medium",
  title = "Toggle Options",
  iconLeftClass = "fas fa-cogs",
  isTextVisible = false,
  ...buttonProps
}: CSSidebarToggleButtonProps) => {
  return (
    <CSSecondaryButton
      {...buttonProps}
      variant={variant}
      className={classNames("csa-sidebar-toggle", className)}
      title={title}
      aria-label={title}
      iconLeftClass={iconLeftClass}
      buttonText={buttonText}
      isTextVisible={isTextVisible}
    />
  );
};

export default CSSidebarToggleButton;
