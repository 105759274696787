import {
  Account,
  CSSelectOption,
  DateFilterSearch,
  DateString,
  HtmlString,
  QuestionAnswerChoiceSimple,
  QuestionCompareResultsFilterLabels,
  QuestionFilterLabelsBase,
  QuestionListFormValues,
  QuestionListTableFilterValues,
  QuestionResultsFilterLabels,
  QuestionSearchArgs,
  QuestionSearchResult,
  QuestionTypeFilter,
} from "types";
import {
  ErrorWithMessage,
  arrayToDelimitedText,
  capitalizeWord,
  extractErrorMessageOrDefault,
  querystring as qs,
  taxonomyUtils,
} from "utils";
import {
  QuestionInputType,
  QuestionOwnershipTerms,
  QuestionRoleType,
  QuestionSearchTextType,
  SharedQuestionTerms,
} from "consts";
import { dateFilterSearchToDateRange, relativeDateToDateRange } from "utils/dates";
import { findInArrayByKeyValue, formatDate, isArray } from "@civicscience/chops";

import { SortTypes } from "utils/search/sorting";

/**
 * Determines the single `QuestionOwnershipTerms` value based on the list of selected values.
 *
 * Our filter dropdown represents the options as a list and this function will map
 * that list to the single value that the API expects.
 */
export const mapQuestionOwnershipType = (selectedTypes: QuestionTypeFilter[] | null | undefined): QuestionTypeFilter =>
  selectedTypes?.length === 1 ? selectedTypes[0] : QuestionOwnershipTerms.ALL;

export const findAnswerChoiceById = (
  answerChoices: QuestionAnswerChoiceSimple[] = [],
  id: string | undefined,
  fallback: string,
) => {
  const answerChoice = answerChoices && id ? findInArrayByKeyValue(answerChoices, "id", id) : undefined;

  return answerChoice?.text ?? fallback;
};

type getRolesFromQuestionRoleParamReturn = {
  isEngagement: true | null | undefined;
  isValue: true | null | undefined;
  isProfile: true | null | undefined;
};

export const getRolesFromQuestionRoleParam = (
  questionRole: string[] | null | undefined = [],
): getRolesFromQuestionRoleParamReturn => {
  const questionRoleIsArray = isArray(questionRole);

  return {
    isEngagement: (questionRoleIsArray && questionRole?.includes(QuestionRoleType.Engagement)) || null,
    isValue: (questionRoleIsArray && questionRole?.includes(QuestionRoleType.Value)) || null,
    isProfile: (questionRoleIsArray && questionRole?.includes(QuestionRoleType.Profile)) || null,
  };
};

/**
 * Gets the single string question role from the role object returned from search.
 */
export const getQuestionRoleFromRoles = (roles: QuestionSearchResult["roles"]): string => {
  if (roles.isEngagement) {
    return QuestionRoleType.Engagement;
  }

  if (roles.isProfile) {
    return QuestionRoleType.Profile;
  }

  if (roles.isValue) {
    return QuestionRoleType.Value;
  }

  return "";
};

type getOptionsFromSharedSharedCategoryParamReturn = {
  isTracking?: true | null;
  isTrending?: true | null;
  isCyclical?: true | null;
  isArchived?: true | null;
};

export const getOptionsFromSharedSharedCategoryParam = (
  questionSharedCategory: string[] | null | undefined = [],
): getOptionsFromSharedSharedCategoryParamReturn => {
  const sharedCategoryIsArray = isArray(questionSharedCategory);

  return {
    isTracking: (sharedCategoryIsArray && questionSharedCategory?.includes(SharedQuestionTerms.TRACKING)) || null,
    isTrending: (sharedCategoryIsArray && questionSharedCategory?.includes(SharedQuestionTerms.TRENDING)) || null,
    isCyclical: (sharedCategoryIsArray && questionSharedCategory?.includes(SharedQuestionTerms.CYCLICAL)) || null,
    isArchived: (sharedCategoryIsArray && questionSharedCategory?.includes(SharedQuestionTerms.ARCHIVED)) || null,
  };
};

export const defaultTypeFilterClearValues = {
  questionType: [],
  questionSharedCategory: [],
  inputType: [],
  isMyFavoritesOnly: false,
  questionRole: [],
};

export const emptyQuestionSearchTypeFilterValues = {
  questionType: [],
  questionSharedCategory: [],
  inputType: [],
  isMyFavoritesOnly: false,
  questionRole: [],
};

export const emptyQuestionSearchFilterValues: QuestionListFormValues = {
  query: "",
  tags: [],
  taxonomyTags: [],
  dateFilter: { kind: "all" },
  ownership: [],
  responseCountGte: 0,
  searchText: [],
  ...emptyQuestionSearchTypeFilterValues,
};

export const defaultQuestionSearchTypeFilterValues = {
  questionType: [],
  questionSharedCategory: [],
  isMyFavoritesOnly: false,
  questionRole: [QuestionRoleType.Value, QuestionRoleType.Profile],
  inputType: [QuestionInputType.Radio, QuestionInputType.Checkbox],
  responseCountGte: 0,
};

export const defaultQuestionSearchFilterValues: QuestionListFormValues = {
  query: "",
  tags: [],
  taxonomyTags: [],
  dateFilter: { kind: "all" },
  ownership: [QuestionOwnershipTerms.CUSTOM, QuestionOwnershipTerms.SYNDICATED],
  searchText: [QuestionSearchTextType.QUESTIONS],
  ...defaultQuestionSearchTypeFilterValues,
};

export const defaultQuestionSearchTableFilterValues = {
  sortTerm: SortTypes.RELEVANCE,
  page: 1,
  size: 25,
};

type QuestionSearchDateFilterParams = {
  startDate: Date | null;
  endDate: Date | null;
  isCurrentlyLive: boolean;
};

type QuestionListParamOverridesProps = {
  // consider naming questionType something else as to not confuse with 'radio' | 'checkbox'
  questionType?: QuestionTypeFilter | QuestionTypeFilter[];
  inputType?: string[];
  sortTerm?: string;
  isMyFavoritesOnly?: boolean;
  taxonomyTags?: string | string[];
  ownership?: string[];
  questionRole?: string[];
  responseCountGte?: number;
  searchText?: string[];
};

const buildWithEmptyQuestionParams = (paramOverrides: QuestionListParamOverridesProps) => {
  const emptyFormFilterValues: QuestionListFormValues = { ...emptyQuestionSearchFilterValues };
  return qs.buildQueryParams({ ...emptyFormFilterValues, ...paramOverrides });
};

const buildWithDefaultQuestionParams = (paramOverrides: QuestionListParamOverridesProps) => {
  const defaultFormFilterValues: QuestionListFormValues = { ...defaultQuestionSearchFilterValues };
  return qs.buildQueryParams({ ...defaultFormFilterValues, ...paramOverrides });
};

/**
 * Returns the query params for a "Favorite Questions" search
 */
export const getFavoriteQuestionsSearchParams = (): string =>
  buildWithEmptyQuestionParams({
    isMyFavoritesOnly: true,
    inputType: [QuestionInputType.Radio, QuestionInputType.Checkbox],
    questionRole: [QuestionRoleType.Engagement, QuestionRoleType.Value, QuestionRoleType.Profile],
    ownership: [QuestionOwnershipTerms.CUSTOM, QuestionOwnershipTerms.SYNDICATED],
    searchText: [QuestionSearchTextType.QUESTIONS],
  });

/**
 * Returns the query params for a "Custom Questions" search
 */
export const getCustomQuestionsSearchParams = (): string =>
  buildWithDefaultQuestionParams({
    questionType: QuestionOwnershipTerms.CUSTOM,
    inputType: [QuestionInputType.Radio, QuestionInputType.Checkbox],
    ownership: [QuestionOwnershipTerms.CUSTOM],
    questionRole: [QuestionRoleType.Engagement, QuestionRoleType.Value, QuestionRoleType.Profile],
    searchText: [QuestionSearchTextType.QUESTIONS],
  });

/**
 * Returns the query params for a "New Questions" search
 */
export const getNewQuestionsSearchParams = (): string =>
  buildWithDefaultQuestionParams({
    inputType: [QuestionInputType.Radio, QuestionInputType.Checkbox],
    sortTerm: SortTypes.NEWEST_CREATED,
    questionRole: [QuestionRoleType.Value, QuestionRoleType.Profile],
    ownership: [QuestionOwnershipTerms.CUSTOM, QuestionOwnershipTerms.SYNDICATED],
    responseCountGte: 1000,
    searchText: [QuestionSearchTextType.QUESTIONS],
  });

/**
 * Returns the query params for a "Trending Questions" search
 */
export const getTrendingQuestionsSearchParams = (): string => {
  const trendingTag = taxonomyUtils.encodeTrendingTagValue();
  return buildWithDefaultQuestionParams({
    inputType: [QuestionInputType.Radio, QuestionInputType.Checkbox],
    taxonomyTags: trendingTag,
    ownership: [QuestionOwnershipTerms.CUSTOM, QuestionOwnershipTerms.SYNDICATED],
    questionRole: [QuestionRoleType.Value, QuestionRoleType.Profile],
    searchText: [QuestionSearchTextType.QUESTIONS],
  });
};

/**
 * Returns the array for Favorites button by looking at current isMyFavoritesOnly value
 */
export const getFavoritesDefault = (isMyFavoritesOnly = false) => {
  return isMyFavoritesOnly ? [SharedQuestionTerms.FAVORITES] : [];
};

/**
 * Maps `DateFilterSearch` to the params that are passed to the search end point.
 * This returns an object so that you can spread/merge it with your existing params.
 */
export const getQuestionSearchDateFilterParams = (
  dateFilterSearch: DateFilterSearch,
): QuestionSearchDateFilterParams => {
  const empty: QuestionSearchDateFilterParams = { isCurrentlyLive: false, startDate: null, endDate: null };

  switch (dateFilterSearch.kind) {
    case "all":
      return empty;
    case "live":
      return { ...empty, isCurrentlyLive: true };
    case "custom-fixed":
      return { ...empty, startDate: dateFilterSearch.startDate, endDate: dateFilterSearch.endDate };
    case "custom-relative":
      return { ...empty, ...relativeDateToDateRange(dateFilterSearch) };
  }
};

type GetQuestionSearchParamsProps = QuestionListFormValues;

/**
 * Maps the values from the question search form to the parameters required by the question search API.
 */
export const getQuestionSearchParams = ({
  dateFilter,
  taxonomyTags,
  ...formValues
}: GetQuestionSearchParamsProps): QuestionSearchArgs => {
  const hasQuestionRole = formValues.questionRole && formValues.questionRole.length > 0;
  const questionOrClassificationRoleValues = hasQuestionRole
    ? getRolesFromQuestionRoleParam(formValues.questionRole)
    : [];

  return {
    ...formValues,
    ...questionOrClassificationRoleValues,
    ...getOptionsFromSharedSharedCategoryParam(formValues.questionSharedCategory),
    ...getQuestionSearchDateFilterParams(dateFilter),
    ...taxonomyUtils.buildApiValues(taxonomyTags),
    questionType: mapQuestionOwnershipType(formValues.ownership),
    searchAnswerOptions: formValues.searchText.includes(QuestionSearchTextType.ANSWERS),
    searchQuestionText: formValues.searchText.includes(QuestionSearchTextType.QUESTIONS),
    searchTags: formValues?.searchText.includes(QuestionSearchTextType.TAGS),
    responseCountGte: formValues.responseCountGte || null,
  };
};

const formatLabelDate = (date: string) =>
  formatDate(date, {
    month: "short",
  });

const getDateFilterLabel = (
  startDate: DateString | null | undefined,
  endDate: DateString | null | undefined,
): HtmlString => {
  if (startDate && endDate) {
    return `from ${formatLabelDate(startDate)} to ${formatLabelDate(endDate)}`;
  }

  if (startDate) {
    return `on or after ${formatLabelDate(startDate)}`;
  }

  if (endDate) {
    return `on or before ${formatLabelDate(endDate)}`;
  }

  return "";
};

type GetAppliedFiltersHtmlProps = QuestionFilterLabelsBase & {
  suffixText?: string;
  hideNetworkText?: boolean;
};

/**
 * Returns a string (may include HTML) representing the provided filters.
 *
 * Any combination of filters may be enabled and the resulting label will still read appropriately.
 */
export const getAppliedFiltersHtml = ({
  startDate,
  endDate,
  segmentName,
  targetName,
  networkName,
  weightingSchemeLabel,
  suffixText,
  hideNetworkText = false,
}: GetAppliedFiltersHtmlProps): HtmlString => {
  return [
    targetName && `for target ${targetName}`,
    segmentName && `in segment ${segmentName}`,
    getDateFilterLabel(startDate, endDate),
    !hideNetworkText && (networkName ? `in network ${networkName}` : "in my account"),
    weightingSchemeLabel && `weighted according to ${weightingSchemeLabel}`,
    suffixText && `| ${suffixText}`,
  ]
    .filter(Boolean)
    .join(" ");
};

/**
 * Returns a string (may include HTML) representing the provided filters.
 *
 * Any combination of filters may be enabled and the resulting label will still read appropriately.
 */
export const getAppliedFiltersLabel = ({
  startDate,
  endDate,
  segmentName,
  targetName,
  networkName,
  weightingSchemeLabel,
  valueGroupingLabel,
  hideNetworkText = false,
}: QuestionResultsFilterLabels): HtmlString => {
  return getAppliedFiltersHtml({
    startDate,
    endDate,
    segmentName,
    targetName,
    networkName,
    weightingSchemeLabel,
    suffixText: valueGroupingLabel ? `Answers grouped by ${valueGroupingLabel}` : undefined,
    hideNetworkText,
  });
};

type GetAppliedFiltersObjectProps = {
  filters: QuestionResultsFilterLabels;
  account?: Account;
};

/**
 * Returns a string (may include HTML) representing the provided filters.
 *
 * Any combination of filters may be enabled and the resulting label will still read appropriately.
 */
export const getAppliedFiltersLabelWithAccount = ({ filters, account }: GetAppliedFiltersObjectProps): HtmlString => {
  return getAppliedFiltersLabel(Object.assign({}, filters ?? {}, { hideNetworkText: account?.isClient ?? false }));
};

/**
 * Returns a string (may include HTML) representing the provided filters.
 *
 * Any combination of filters for compare charts may be enabled and the resulting label will still read appropriately.
 */
export const getAppliedCompareFiltersLabel = ({
  startDate,
  endDate,
  segmentName,
  targetName,
  networkName,
  weightingSchemeLabel,
  hideNetworkText,
}: QuestionCompareResultsFilterLabels): HtmlString => {
  return getAppliedFiltersHtml({
    startDate,
    endDate,
    segmentName,
    targetName,
    networkName,
    weightingSchemeLabel,
    hideNetworkText,
  });
};

export const strengthOfAssociationText = (tValue: number) => {
  let tValText = "";
  if (tValue < 0.05) {
    tValText = "Low";
  } else if (tValue < 0.1) {
    tValText = "Medium";
  } else if (tValue < 0.2) {
    tValText = "High";
  } else {
    tValText = "Very High";
  }

  return `${tValText} Strength of Association`;
};

type GetQuestionResultsErrorMsgProps = {
  error?: ErrorWithMessage;
  fallback?: string;
};

export const getQuestionResultsErrorMsg = ({
  error,
  fallback = "Error loading question results data",
}: GetQuestionResultsErrorMsgProps) => extractErrorMessageOrDefault(error, fallback);

/**
 * Builds question search params into a human-readable string.
 * NOTE: This is biased towards question list dashlets at the moment.
 * If we want to support the full question search we will most likely need to add to this function.
 */
export const getQuestionSearchFilterLabel = (filters: QuestionListFormValues & QuestionListTableFilterValues) => {
  const { startDate, endDate } = dateFilterSearchToDateRange(filters.dateFilter);

  const filterTextParts = [
    filters.isMyFavoritesOnly ? "my favorite" : null,
    filters.questionType ? arrayToDelimitedText(filters.questionType) : null,
    filters.questionRole ? arrayToDelimitedText(filters.questionRole.map(getQuestionRoleLabel)) : null,
    "questions",
    getDateFilterLabel(startDate?.toString(), endDate?.toString()),
    // TODO: Do we want to add in sort information here?
    // "ordered from newest to oldest", "ordered by responses", etc.?
  ].filter(Boolean);

  // if the only entry is the static "questions" entry then return empty as there are no applied filters.
  if (filterTextParts.length === 1) {
    return "";
  }

  return capitalizeWord(filterTextParts.join(" "));
};

/**
 * Converts a `QuestionRoleType` to a display string.
 * If the `role` can't be matched an empty string is returned.
 */
export const getQuestionRoleLabel = (role: string): string => {
  switch (role.toLowerCase()) {
    case QuestionRoleType.Engagement:
      return "News / Pop Culture";
    case QuestionRoleType.Profile:
      return "Profile";
    case QuestionRoleType.Value:
      return "Value";
    case QuestionRoleType.Quiz:
      return "Quiz";
  }

  return "";
};

export const searchTextGroupOptions: CSSelectOption[] = [
  { text: "Questions", value: QuestionSearchTextType.QUESTIONS },
  { text: "Answers", value: QuestionSearchTextType.ANSWERS },
  { text: "Tags", value: QuestionSearchTextType.TAGS },
];

export const questionRoleGroupOptions: CSSelectOption[] = [
  { text: getQuestionRoleLabel(QuestionRoleType.Engagement), value: QuestionRoleType.Engagement },
  { text: getQuestionRoleLabel(QuestionRoleType.Value), value: QuestionRoleType.Value },
  { text: getQuestionRoleLabel(QuestionRoleType.Profile), value: QuestionRoleType.Profile },
];

export const ownershipGroupOptions: CSSelectOption[] = [
  { text: "Custom", value: QuestionOwnershipTerms.CUSTOM },
  { text: "Syndicated", value: QuestionOwnershipTerms.SYNDICATED },
];

export const favoritesGroupOptions: CSSelectOption[] = [{ text: "Favorites", value: SharedQuestionTerms.FAVORITES }];

export const questionSharedCategoryGroupOptions: CSSelectOption[] = [
  { text: "Tracking", value: SharedQuestionTerms.TRACKING },
  { text: "Cyclical", value: SharedQuestionTerms.CYCLICAL },
  { text: "Archived", value: SharedQuestionTerms.ARCHIVED },
];

export const inputTypeGroupOptions: CSSelectOption[] = [
  { text: "Radio", value: QuestionInputType.Radio },
  { text: "Checkbox", value: QuestionInputType.Checkbox },
];

export const QuestionRepeatTypeInt = {
  Never: 2147483647,
  Immediately: 0,
  After: 1,
};

/**
 * Get text to display how often a question can be asked based
 * on repeatIneligibleSeconds.
 */
export const repeatableStatusText = (repeatIneligibleSeconds: number) => {
  if (repeatIneligibleSeconds === QuestionRepeatTypeInt.Never) {
    return "Never";
  } else if (repeatIneligibleSeconds === QuestionRepeatTypeInt.Immediately) {
    return "Immediately";
  } else {
    return `After ${repeatIneligibleSeconds / 86400} days`;
  }
};
