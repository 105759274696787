import PropTypes from "prop-types";

/**
 * A prop which can be "rendered" by React.
 */
export const Renderable = PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]);

/**
 * A common definition for `children` in React where a component
 * accepts a single ReactNode or an array of them.
 */
export const Children = Renderable;

/**
 * Extends this modules `Children` type (above) by marking it as required.
 */
export const RequiredChildren = Children.isRequired;

/**
 * Represents a value that can be passed to a Date constructor: a string, a number, or a Date itself.
 */
export const DateLike = PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]);
