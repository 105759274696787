import CSSecondaryButton, { CSSecondaryButtonProps } from "components/shared/button/CSSecondaryButton";
import React, { ReactNode } from "react";

import classNames from "classnames";

type CSCloseButtonProps = Omit<CSSecondaryButtonProps, "children" | "buttonText"> & {
  buttonText?: ReactNode;
  isTextVisible?: boolean;
};

/**
 * Renders a button that is used for a close button.
 * See https://react-bootstrap.github.io/components/buttons/#api for props.
 */
const CSCloseButton = ({
  className,
  variant = "close",
  buttonText = "Close",
  isSubmitting = false,
  isTextVisible = false,
  ...buttonProps
}: CSCloseButtonProps) => {
  return (
    <CSSecondaryButton
      {...buttonProps}
      variant={variant}
      className={classNames("cs-close-btn", className)}
      disabled={isSubmitting}
      buttonText={buttonText}
      isTextVisible={isTextVisible}
    />
  );
};

export default CSCloseButton;
