import "components/shared/button/CSSubmitButton.scss";
import CSPrimaryButton, { CSPrimaryButtonProps } from "components/shared/button/CSPrimaryButton";
import React, { ReactNode } from "react";

import classNames from "classnames";

export type CSSubmitButtonProps = Omit<CSPrimaryButtonProps, "children" | "buttonText"> & {
  buttonText?: ReactNode;
  isSubmitting?: boolean;
  isTextVisible?: boolean;
  children?: ReactNode;
  iconLeftClass?: string;
  iconRightClass?: string;
};

/**
 * Renders a button that is used for a submit button within a form.
 * See https://react-bootstrap.github.io/components/buttons/#api for props.
 */
const CSSubmitButton = ({
  className,
  type = "submit",
  buttonText = "Submit",
  variant = "primary",
  isSubmitting = false,
  isTextVisible = true,
  iconLeftClass,
  iconRightClass,
  disabled = false,
  children,
  ...buttonProps
}: CSSubmitButtonProps) => {
  return (
    <CSPrimaryButton
      {...buttonProps}
      type={type}
      variant={variant}
      className={classNames("csa-submit-btn", className)}
      disabled={disabled}
      buttonText={buttonText}
      isSubmitting={isSubmitting}
      iconLeftClass={iconLeftClass}
      iconRightClass={iconRightClass}
      isTextVisible={isTextVisible}
    >
      {children}
    </CSPrimaryButton>
  );
};

export default CSSubmitButton;
