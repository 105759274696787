import { Account, AuthContextShape, AuthState, BootstrapData, UserWithPermissions } from "types";
import { AuthAPI, clearApiAuthState } from "api";
import { BugsnagUtils, bootAnalytics, shutdownIntercom, trackEvent, usePageFeedback } from "utils";
import { CSProvider, setupContext, useCSContext } from "context/useCSContext";
import React, { ReactNode, useCallback, useEffect, useState } from "react";

import { Analytics } from "consts";
import { AuthStatus } from "consts";
import bootPosthog from "utils/analytics/bootPosthog";
import { useAuth0 } from "@auth0/auth0-react";
import { usePostHog } from "posthog-js/react";
import { useQueryClient } from "react-query";

const { InitStatus, SuccessStatus, ErrorStatus, PendingStatus } = AuthStatus;

// We default to PendingStatus to ensure no 'flashes' of apps via App.js
const defaultAuthState: AuthState = {
  status: PendingStatus,
  error: null,
};

/**
 * Helper to construct an AuthState.
 */
const buildAuthState = (toAuthState: AuthState = defaultAuthState) => ({ ...defaultAuthState, ...toAuthState });

type AuthProviderProps = {
  whenLoading: () => ReactNode;
  whenUnAuthenticated: () => ReactNode;
  whenAuthenticated: () => ReactNode;
};

export const Context = setupContext<AuthContextShape>("AuthContext");

const AuthProvider = ({ whenLoading, whenAuthenticated, whenUnAuthenticated }: AuthProviderProps) => {
  const auth0 = useAuth0();
  const queryClient = useQueryClient();
  const [currentUser, setCurrentUser] = useState<UserWithPermissions | undefined>(undefined);
  const [authState, setAuthState] = useState<AuthState>(defaultAuthState);
  const { status = "" } = authState;
  const posthog = usePostHog();

  const feedback = usePageFeedback();

  const onSetCurrentAccount = (response: Account) => {
    currentUser && onSetCurrentUser({ ...currentUser, account: response, accountRefid: response.accountId });
    BugsnagUtils.addMetaData("user", { accountId: `${response.accountId}` });
  };

  /**
   * Sets the current user - both initial login and impersonation.
   */
  const onSetCurrentUser = useCallback(
    (userResponse: UserWithPermissions) => {
      setCurrentUser(userResponse);
      queryClient.clear();
      BugsnagUtils.setUser(userResponse);
    },
    [queryClient],
  );

  /**
   * Handler called when a user is successfully authenticated.
   */
  const handleAuthSuccess = useCallback(
    (user: UserWithPermissions) => {
      onSetCurrentUser(user);
      setAuthState(buildAuthState({ status: SuccessStatus }));
    },
    [onSetCurrentUser],
  );

  /**
   * Attempts to login and bootstrap the app.
   * Context method to trigger API logout.
   */
  const login = async (username: string, password: string) => {
    try {
      const authData = await AuthAPI.getToken(username, password);
      bootstrap({ kind: "cs-core", authData });
      trackEvent(Analytics.LOGIN);
    } catch {
      throw new Error("Failed to login");
    }
  };

  /**
   * Handler called after the API logout call.
   * We call this regardless of success or error of the API.
   * If for some reason the logout API call fails, we still want to clear our
   * local state and force the user to log in again.
   */
  const handleLogoutComplete = (nextState: AuthState) => {
    setCurrentUser(undefined);
    shutdownIntercom();
    clearApiAuthState();
    queryClient.clear();
    BugsnagUtils.clearUser();
    setAuthState(nextState);
  };

  /**
   * Context method to trigger API logout.
   */
  const logout = async () => {
    setAuthState(buildAuthState({ status: PendingStatus }));

    try {
      await (auth0.isAuthenticated
        ? auth0.logout({ logoutParams: { returnTo: window.location.origin } })
        : AuthAPI.logout());
      handleLogoutComplete(buildAuthState({ status: InitStatus }));
    } catch (error) {
      handleLogoutComplete(buildAuthState({ status: ErrorStatus, error }));
    }
  };

  /**
   * Refresh or Login User depending on given authData.
   */
  const bootstrap = useCallback(
    (boostrapData: BootstrapData) => {
      AuthAPI.bootstrapApp(boostrapData)
        .then(handleAuthSuccess)
        .catch((err) => {
          setAuthState(buildAuthState({ status: ErrorStatus, error: err }));
        });
    },
    [handleAuthSuccess, setAuthState],
  );

  /**
   * When User or Account changes, call posthog and boot analytics
   */
  useEffect(() => {
    if (currentUser) {
      bootPosthog(posthog, currentUser);
      bootAnalytics(currentUser);
    }
  }, [posthog, currentUser]);

  /**
   * Wait for Auth0 to load and the check if the user is authenticated via Auth0.
   * If they are, we can bootstrap the app with the Auth0 flow.
   * If they are not, bootstrap the app using Core API's auth flow.
   */
  useEffect(() => {
    if (auth0.isLoading) return;

    auth0.isAuthenticated
      ? bootstrap({
          kind: "auth0",
          fetchAccessToken: auth0.getAccessTokenSilently,
        })
      : bootstrap({ kind: "token-refresh" });
  }, [auth0.isLoading, auth0.isAuthenticated, auth0.getAccessTokenSilently, bootstrap]);

  return (
    <>
      <CSProvider
        Context={Context}
        args={{
          onSetCurrentAccount,
          currentUser,
          onSetCurrentUser,
          login,
          logout,
        }}
        requiredKeys={[]}
        feedback={feedback}
      >
        {status !== PendingStatus && currentUser && whenAuthenticated()}
        {status !== PendingStatus && !currentUser && whenUnAuthenticated()}

        {/* Special case where we dont require fields for auth vs unauth to expose the login fn */}
        {/* The unauthenticated app will obviously not have a currentUser */}
        {/* TODO: Think about authContext & authContextRaw to differentiate the two */}
        {status === PendingStatus && !currentUser && whenLoading()}
      </CSProvider>
    </>
  );
};

/**
 * The Account State Hook.
 *
 * Optionally transform the data fetched from the hook before returning it.
 */
export const useAuthState = () => {
  const contextState = useCSContext({
    Context,
    noProviderMsg: "Auth Context used without Auth Provider.",
  });

  return {
    ...contextState,
    currentUserIsImpersonated: contextState.currentUser?.isImpersonated,
    currentAccount: contextState.currentUser?.account,
    isInternal: contextState.currentUser?.account.isInternal,
  };
};

export default AuthProvider;
