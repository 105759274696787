import { isNullOrUndefOrEmpty } from "@civicscience/chops";

/**
 * Declare GTM's `dataLayer` property for use within this file.
 * This, combined with the declare below, makes this available within the scope of this file.
 *
 * https://github.com/vercel/next.js/discussions/20784#discussioncomment-4101864
 */
type WindowWithDataLayer = Window & {
  dataLayer: Record<string, unknown>[];
};

declare const window: WindowWithDataLayer;

/**
 * Send dataLayer object to analytics platforms
 *
 * @param dataLayerObj {Object} object from API response
 */
const pushToDataLayer = (dataLayerObj: Record<string, unknown>) => {
  !isNullOrUndefOrEmpty(dataLayerObj) && window.dataLayer && window.dataLayer.push(dataLayerObj);
};

export default pushToDataLayer;
