import API from "api/client";
import { envConfig } from "config";

const { profileAPI } = envConfig;

/**
 * @typedef { import('consts/UserPermissionTypes').UserPermissionType } UserPermissionType
 * @typedef { import('types').User } User
 * @typedef { import('types').UserWithPermissions } UserWithPermissions
 * @typedef { import('types').Account } Account
 */

/**
 * @returns {Promise<User>}
 */
const getCurrentUser = () => {
  return API.get(`${profileAPI}`).then((res) => res.data);
};

/**
 * @returns {Promise<{}>}
 */
const getUserNotifications = () => {
  return API.get(`${profileAPI}notifications`).then((res) => res.data);
};

/**
 * Fetches the permissions for the current user.
 * See `getUserWithPermissions` for fetching the user with permissions in a single response.
 * @returns {Promise<{ permissions: UserPermissionType[] }>}
 */
const getPermissions = () => {
  return API.get(`${profileAPI}permissions`).then((res) => res.data);
};

/**
 * Combines the user and permissions API calls into one response.
 * The result is a user with an added `permissions` array.
 * @returns {Promise<UserWithPermissions>}
 */
const getUserWithPermissions = async () => {
  const [user, { permissions }] = await Promise.all([getCurrentUser(), getPermissions()]);
  return {
    ...user,
    permissions,
  };
};

/**
 * @param req {object}
 * @param req.userId {int}
 * @returns {Promise<{}>}
 */
const impersonateUser = (req) => {
  return API.put(`${profileAPI}impersonate`, req).then((res) => res.data);
};

/**
 * First impersonates the user and then calls to fetch the permissions for the impersonated user.
 * The result is a user object populated with permissions.
 * @param req {object}
 * @param req.userId {int}
 * @returns {Promise<UserWithPermissions>}
 */
const impersonateUserWithPermissions = async (req) => {
  const user = await impersonateUser(req);
  const { permissions } = await getPermissions();

  return { ...user, permissions };
};

/**
 * @returns {Promise<{}>}
 */
const stopImpersonatingUser = () => {
  return API.put(`${profileAPI}stop-impersonating`).then((res) => res.data);
};

/**
 * Stops impersonating and restores the original user.
 * The result is the original user object populated with the original user's permissions.
 * @returns {Promise<UserWithPermissions>}
 */
const stopImpersonatingUserWithPermissions = async () => {
  const user = await stopImpersonatingUser();
  const { permissions } = await getPermissions();

  return { ...user, permissions };
};

/**
 * @param {number} accountRefid
 * @returns {Promise<User>}
 */
const switchUserAccount = (accountRefid) => {
  return API.put(`${profileAPI}switch-account`, { accountRefid }).then((res) => res.data);
};

/**
 * @param {object} req
 * @param {number} req.firstName
 * @param {number} req.lastName
 * @param {number} req.email
 * @param {number} req.locale
 * @returns {Promise<{}>}
 */
const updateUser = (req) => {
  return API.put(`${profileAPI}`, req).then((res) => res.data);
};

/**
 * @param {object} req
 * @param {number} req.firstName
 * @param {number} req.lastName
 * @param {number} req.email
 * @param {number} req.locale
 * The result is the updated user object populated with the user's permissions.
 * @returns {Promise<{}>}
 */
const updateUserWithPermissions = async (req) => {
  const user = await updateUser(req);
  const { permissions } = await getPermissions();

  return { ...user, permissions };
};

/**
 * @param {Array} notifications list of notifications
 * @param {object} notifications[i] notification
 * @param {string|number|null} notifications[i].id
 * @param {string|number} notifications[i].accountRefid
 * @param {string|number} notifications[i].userRefid
 * @param {string} notifications[i].notificationTypeUri
 * @param {string} notifications[i].frequencyUri
 * @returns {Promise<{}>}
 */
const updateUserNotifications = (notifications) => {
  return API.put(`${profileAPI}notifications`, notifications).then((res) => res.data);
};

/**
 * @param {string} password
 * @returns {Promise<{}>}
 */
const updateUserPassword = (password) => {
  return API.put(`${profileAPI}password`, { password: password }).then((res) => res.data);
};

/**
 * @returns {Promise<{}>}
 */
const updateUserTermsOfUse = () => {
  return API.put(`${profileAPI}accept-terms`).then((res) => res.data);
};

/**
 * Sign terms of use and update user.
 * The result is the updated user object populated with the user's permissions.
 * @returns {Promise<{}>}
 */
const updateUserTermsOfUseWithPermissions = async () => {
  const user = await updateUserTermsOfUse();
  const { permissions } = await getPermissions();

  return { ...user, permissions };
};

/**
 * Get list of accounts a user is a member of.
 * Majority of the time a user is a member of just one.
 *
 * @returns {Promise<Account[]>}
 */
const getUserAccounts = () => {
  return API.get(`${profileAPI}accounts`).then((res) => res.data);
};

export default {
  get: getCurrentUser,
  getUserWithPermissions,
  getPermissions,
  getAccounts: getUserAccounts,
  getNotifications: getUserNotifications,
  impersonate: impersonateUserWithPermissions,
  stopImpersonating: stopImpersonatingUserWithPermissions,
  switchAccount: switchUserAccount,
  update: updateUserWithPermissions,
  updateNotifications: updateUserNotifications,
  updatePassword: updateUserPassword,
  updateTOU: updateUserTermsOfUseWithPermissions,
};
