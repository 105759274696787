import { ObjectValues } from "types";

const QuestionRoleType = {
  Engagement: "engagement",
  Quiz: "quiz",
  Value: "value",
  Profile: "profile",
} as const;

export type QuestionRoleTypeTerm = ObjectValues<typeof QuestionRoleType>;

export default QuestionRoleType;
