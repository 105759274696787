import { isKeyValueInLocalStorage, isMobileScreenWidth, setLocalStorageValue } from "utils";
import { useState } from "react";

type useLeftNavHelpersReturn = {
  navExpanded: boolean;
  onLeftNavToggle: () => void;
  onNavigate: () => void;
};

const useLeftNavHelpers = (): useLeftNavHelpersReturn => {
  const leftNavExpandedStorageKey = "cs-left-nav-expanded";
  const leftNavExpandedStorage = isKeyValueInLocalStorage(leftNavExpandedStorageKey, "true", true);
  const [navExpanded, setNavExpanded] = useState(leftNavExpandedStorage);

  const setLeftExpanded = (isExpanded: boolean) => {
    setLocalStorageValue(leftNavExpandedStorageKey, isExpanded.toString());
    setNavExpanded(isExpanded);
  };

  const onLeftNavToggle = () => setLeftExpanded(!navExpanded);

  const onNavigate = () => isMobileScreenWidth() && setLeftExpanded(false);

  return {
    navExpanded,
    onLeftNavToggle,
    onNavigate,
  };
};

export default useLeftNavHelpers;
