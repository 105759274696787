import { ObjectValues } from "types";

const QuestionInputType = {
  Radio: "radio",
  Checkbox: "checkbox",
} as const;

export type QuestionInputTypeTerm = ObjectValues<typeof QuestionInputType>;

export default QuestionInputType;
