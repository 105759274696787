//TODO: Move to Chops?

const setLocalStorageValue = (storageKey: string, itemValue: string): void => {
  try {
    localStorage.setItem(storageKey, itemValue);
  } catch {
    //Do Nothing
  }
};

export default setLocalStorageValue;
