import { debug } from "utils";
import { lazy } from "react";

const getPageHasForceRefreshed = () => {
  try {
    return JSON.parse(window.localStorage.getItem("page-has-been-force-refreshed") || "false");
  } catch (e) {
    // since we failed to find a value it is impossible to know if we've already retried.
    // we'll return `true` to act as though we've already retried - this avoids the potential of infinitely retrying.
    return true;
  }
};

const setPageHasForceRefreshed = (val) => {
  try {
    window.localStorage.setItem("page-has-been-force-refreshed", JSON.stringify(val));
  } catch {
    // nothing to do here as a setter returns void anyway
  }
};

/**
 * Wrapper over react `lazy` which will reload the current page if a chunk fails to load.
 * The rationale is that if a chunk fails to load there is a good chance the user is
 * currently running an older version of the application.
 * A reload will fetch the latest version of the app and hopefully fix the failed chunk.
 *
 * Lightly modified from:
 * https://raphael-leger.medium.com/react-webpack-chunkloaderror-loading-chunk-x-failed-ac385bd110e0
 */
const lazyWithRetry = (componentImport) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = getPageHasForceRefreshed();

    try {
      const component = await componentImport();
      setPageHasForceRefreshed(false);
      return component;
    } catch (error) {
      if (pageHasAlreadyBeenForceRefreshed) {
        debug.warn("Failed to lazy load a chunk for the second time.");
        // We've hit an error loading a chunk and have already tried to reload the page to fix it.
        // We'll now let the error propagate so that our error boundary and BugSnag are triggered.
        throw error;
      }

      debug.warn("Failed to lazy load a chunk for the first time. Reloading the page.");
      // We've hit an error loading a JS Chunk.
      // One likely cause is that a newer version of the app has been deployed.
      // We'll attempt to reload the page (just once) to see if getting the latest version of the app fixes the error.
      setPageHasForceRefreshed(true);
      return window.location.reload();
    }
  });

export default lazyWithRetry;
