import { debugTimer, humps } from "@civicscience/chops";

import MockInsights from "mocks/get-insights-mock.json";

export type Insight = Omit<(typeof MockInsights)[number], "question_id"> & {
  questionId: number;
};

//FIXME: This is for demo purposes only and will need an actual api down the road
const getInsights = async (): Promise<Insight[]> => {
  await debugTimer(500);

  return Object.assign([], humps.camelizeKeys(MockInsights));
};

export default {
  list: getInsights,
};
