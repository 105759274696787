import { Debugger } from "@civicscience/chops/src/utils/debug/debugConsole";
import { envConfig } from "config";

const { isDebugMode } = envConfig;

/**
 *
 * @type {{warn: function, log: function, error: function}|console}
 */
const debug = Debugger("CSA:", isDebugMode || isDebugMode === "true");

export default debug;
