import "components/shared/button/CSPrimaryButton.scss";

import { Button, ButtonProps } from "react-bootstrap";
import React, { ReactNode } from "react";

import CSIconWithText from "components/shared/icon/CSIconWithText";
import classNames from "classnames";

export type CSPrimaryButtonProps = Omit<ButtonProps, "children"> & {
  buttonText: ReactNode;
  isSubmitting?: boolean;
  isTextVisible?: boolean;
  children?: ReactNode;
  iconLeftClass?: string;
  iconRightClass?: string;
  textClassName?: string;
};

/**
 * Renders a button that is used for a primary button that does not submit.
 * See https://react-bootstrap.github.io/components/buttons/#api for props.
 */
const CSPrimaryButton = ({
  className,
  type = "button",
  buttonText,
  variant = "primary",
  isSubmitting = false,
  isTextVisible = true,
  iconLeftClass,
  iconRightClass,
  textClassName,
  children,
  disabled = isSubmitting,
  ...buttonProps
}: CSPrimaryButtonProps) => {
  return (
    <Button
      {...buttonProps}
      type={type}
      variant={variant}
      className={classNames("csa-primary-btn", className)}
      disabled={disabled}
    >
      <CSIconWithText
        text={buttonText}
        isTextVisible={isTextVisible}
        iconLeftClassName={isSubmitting ? "csa-primary-btn-spinner csa-i fas fa-sync fa-spin" : iconLeftClass}
        iconRightClassName={iconRightClass}
        textClassName={classNames("csa-primary-btn-text", textClassName)}
      />
      {children}
    </Button>
  );
};

export default CSPrimaryButton;
